import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../../helpers/api";
import App from "../../helpers/app";
import Me, { User } from "../../helpers/me";
import { LISTITEMS } from "../data/template-search";
import { ConfigService } from "../services/config.service";
import { LayoutService } from "../services/layout.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  apiCall = this.api.new().silent();
  sum = 30;
  throttle = 0;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  configS = {
    suppressScrollX: true,
  };
  @ViewChild(PerfectScrollbarDirective, { static: false })
  perfectScroll?: PerfectScrollbarDirective;

  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = "assets/img/logo.png";
  menuPosition = "Side";
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  user: User | null = null;
  countNotifications = 0;
  notifications = [];
  countAtualizacoes = 0;
  needsApproval = true;
  feedbackNotification = null;

  atualizacoes = [];
  @ViewChild("search") searchElement: ElementRef;
  @ViewChildren("searchResults") searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  public config: any = {};

  constructor(
    public translate: TranslateService,
    private modal: NgbModal,
    private layoutService: LayoutService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private me: Me,
    private app: App,
    private api: Api,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private clipboardApi: ClipboardService
  ) {
    this.me
      .get()
      .pipe(take(1))
      .subscribe((user) => {
        if (user) {
          this.user = user;

          let jUser = null;
          jUser = JSON.parse(JSON.stringify(this.user));

          if (jUser) {
            this.feedbackNotification = jUser.loadFeedbackNotification;
          }
        }
      });

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
      this.hideSidebar = !isShow;
    });
  }

  goTelegram() {
    window.open("https://t.me/+Hw52J1-zBu9kZGM5", "_blank");
  }

  isAssinante = false;

  async ngOnInit() {
    const self = this;
    self.countAtualizacoes = 0;
    self.atualizacoes = [];
    self.me
      .get()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          // @ts-ignore
          // self.user.photo_url = data.photo_url
        }
      });

    self.apiCall
      .get("seller/me/notifications/0/" + this.sum)
      .subscribe(async (data) => {
        if (data.return) {
          if (data.return.rows.filter((n) => n.status == 0).length > 0)
            self.countNotifications = data.return.rows.filter(
              (n) => n.status == 0
            ).length;
          self.notifications = data.return.rows;
        }
      });
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const datapagamentos = await self.apiCall
      .get("seller/me/verify/subscription/plataforma")
      .pipe(take(1))
      .toPromise();
    if (datapagamentos.return) {
      if (datapagamentos.return.plano_ativo == true) {
        this.isAssinante = true;
      }
    }
    await self.spinner.hide();
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    const atualizacoes = await self.apiCall
      .get("updatessistema")
      .pipe(take(1))
      .toPromise();
    if (atualizacoes.return) {
      self.atualizacoes = atualizacoes.return;
    }

    const cacheatualizacoes = localStorage.getItem("atualizacoes");
    if (cacheatualizacoes) {
      const listaatualizacoes = JSON.parse(cacheatualizacoes);
      if (self.atualizacoes.length > listaatualizacoes.length) {
        self.countAtualizacoes =
          self.atualizacoes.length - listaatualizacoes.length;
      }
    } else {
      self.countAtualizacoes = self.atualizacoes.length;
    }
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );
  }
  formatDate(date) {
    let ontem = moment().subtract(1, "days").toDate();
    let hoje = moment().toDate();

    if (moment(date).isSame(hoje, "day")) {
      return "Hoje";
    } else if (moment(date).isSame(ontem, "day")) {
      return "Ontem";
    } else {
      return moment(date).format("DD/MM/YYYY");
    }
  }
  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {
    if (
      this.config.layout.menuPosition &&
      this.config.layout.menuPosition.toString().trim() != ""
    ) {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  clickOnNotifications(event) {
    const listaNaoLidos = this.notifications.filter((n) => n.status == 0);
    if (event && listaNaoLidos.length > 0) {
      let ids = [];
      for (let n of listaNaoLidos) {
        ids.push(n.id);
      }
      for (let n of this.notifications) {
        n.status = 1;
      }
      this.countNotifications = 0;
      this.apiCall
        .put("seller/me/notifications/status", { ids: ids })
        .subscribe(async (data) => {});
    }
  }

  clickOnAtualizacoes(event) {
    const self = this;
    localStorage.setItem("atualizacoes", JSON.stringify(self.atualizacoes));
    self.countAtualizacoes = 0;
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add(
        "first-active-item"
      );
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove(
        "first-active-item"
      );
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = "";
    this.seachTextEmpty.emit(true);
  }

  onScrollDown(ev) {
    const start = this.sum;
    this.sum += 30;
    this.appendItems(start, this.sum);
    this.direction = "down";
  }

  onUp(ev) {
    this.sum += 30;

    this.direction = "up";
  }

  viewStoreLink(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  openModalNormal(content) {
    this.needsApproval = false;
    this.modal.open(content, { centered: true });
  }

  copiedAndroid = false;
  copiedIphone = false;

  copyIphone(val) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.copiedIphone = true;
    setTimeout(() => (this.copiedIphone = false), 5000);
  }
  copyAndroid(val) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.copiedAndroid = true;

    setTimeout(() => (this.copiedAndroid = false), 5000);
  }

  linkConviteGerado = null;
  gerarLinkConvite() {
    const self = this;
    self.spinner.show("invites", {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall.get("seller/stores/link").subscribe(async (data) => {
      await self.spinner.hide("invites");
      if (data.success) {
        this.linkConviteGerado = data.return;
        this.clipboardApi.copyFromContent(this.linkConviteGerado);
      } else {
        await self.app.alert("Ops :(", data.message, "error");
      }
    });
  }
  appendItems(startIndex, endIndex) {
    const self = this;
    self.apiCall
      .get("seller/me/notifications/" + startIndex + "/" + endIndex)
      .subscribe(async (data) => {
        if (data.return) {
          if (data.return.rows.filter((n) => n.status == 0).length > 0)
            self.countNotifications =
              self.countNotifications +
              data.return.rows.filter((n) => n.status == 0).length;
          for (let n of data.return.rows) {
            if (!self.notifications.find((t) => t.id == n.id))
              self.notifications.push(n);
          }
          self.notifications = [...self.notifications];
          self.perfectScroll.update();
          self.perfectScroll.scrollToTop(0, 1);
        }
      });
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != "") {
        this.control.setValue("");
        this.searchOpenClass = "";
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === "en") {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    } else if (language === "es") {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    } else if (language === "pt") {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    } else if (language === "de") {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = "open";
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = "";
    }
    this.seachTextEmpty.emit(true);
  }

  async logout() {
    await this.me.logout();
    this.router.navigate(["/pages/login"]);
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  updateNotificationPreferences(modal) {
    const self = this;

    Swal.fire({
      title: "Tem certeza?",
      text: this.feedbackNotification
        ? "Deseja ativar as notificações de feedback de carga?"
        : "Deseja desativar as notificações de feedback de carga?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });

        self.apiCall
          .put("seller/me/updatenotificationpreferences", {
            loadFeedbackNotification: this.feedbackNotification,
          })
          .subscribe(async (data) => {
            if (data.success) {
              const response = await this.me
                .get(false)
                .pipe(take(1))
                .toPromise();

              self.user = response || null;

              this.spinner.hide();
              await this.app.alert(
                "Tudo certo!",
                "Preferência da notificação alterada com sucesso",
                "success"
              );
              modal.close("Close click");
              this.ngOnInit();
            } else {
              await this.app.alert("Ops :(", data.message, "error");
            }
          });
      } else if (result.isDismissed) {
        this.feedbackNotification = !this.feedbackNotification;
      }
    });
  }

  getNotificationLink(notification) {
    if (notification.sellerCustomer == null) {
      return "";
    } else {
      return "/page/customer/" + notification.sellerCustomer + "/2";
    }
  }
}
