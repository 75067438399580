import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import Me from 'app/helpers/me';
import { data } from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-opd-academy',
  templateUrl: './opd-academy.component.html',
  styleUrls: ['./opd-academy.component.scss']
})
export class OpdAcademyComponent implements OnInit {
  apiCall = this.api.new().silent();


  elegiblePlan = false
  urlToPlay = null
  safeURL = null
  activedTab = "desafio"
  objectives = null;
  userObjectives = null;
  userObjectivesFinish = null;
  userObjectivesNotFinish = null;
  objectivesFinished = false
  isTruePlan = false
  mentorings: any;
  desafio30Alunos: any;
  freeContent: any;

  constructor(private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,
    private me: Me,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) {



  }

  async ngOnInit() {

    const self = this

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const dataPagamentos = await self.apiCall.get('seller/me/verify/subscription/plataforma').pipe(take(1)).toPromise()

    if (dataPagamentos && dataPagamentos.return) {

      console.log("Plan", dataPagamentos.return.plan_identifier)
      console.log("Ativo", dataPagamentos.return.plano_ativo)

      /*if(dataPagamentos.return.plan_identifier && dataPagamentos.return.plano_ativo == true){
      self.elegiblePlan = true
      self.objectivesFinished = true
      }*/
      if (dataPagamentos.return.plan_identifier && (dataPagamentos.return.plan_identifier == 'plano_3_parcelado' || dataPagamentos.return.plan_identifier == 'plano_3_boleto' || dataPagamentos.return.plan_identifier == 'plano_3_hotmart' || dataPagamentos.return.plan_identifier == 'plano_3_parcelado_iago')) {
        self.elegiblePlan = true
        self.objectivesFinished = true
      }

      if (dataPagamentos.return.plan_identifier && (dataPagamentos.return.plan_identifier == 'plano_3_parcelado' || dataPagamentos.return.plan_identifier == 'plano_3_boleto' || dataPagamentos.return.plan_identifier == 'plano_3_hotmart' || dataPagamentos.return.plan_identifier == 'plano_3_parcelado_iago')) {
        self.isTruePlan = true

      }
    }

    const userObjectives = await self.apiCall.get('userObjectives').pipe(take(1)).toPromise()
    if (userObjectives.return) {
      this.userObjectives = userObjectives.return.userObjectives
      this.userObjectivesFinish = this.userObjectives.filter(x => x.status == true)
      this.userObjectivesNotFinish = this.userObjectives.filter(x => x.status == false)

      if (this.userObjectivesFinish.length > 0 && this.userObjectivesFinish.length == this.userObjectives.length) {
        self.objectivesFinished = true
      }

    }

    this.route.queryParams.subscribe((params) => {
      if (params['metas']) {
        this.activedTab = 'metas'
      }
    })

    const aulas = await self.apiCall.get('me/opdacademy').pipe(take(1)).toPromise()
    if (aulas.return) {
      const aulasReturn = aulas.return
      this.mentorings = aulasReturn.filter(x => x.OpdAcademyCategory.id == 1) //Mentorias
      this.desafio30Alunos = aulasReturn.filter(x => x.OpdAcademyCategory.id == 2) //Desafio 30 Alunos
      this.freeContent = aulasReturn.filter(x => x.OpdAcademyCategory.id == 3) //Conteúdos Especiais

    }

    self.spinner.hide();

  }


  viewMidia(contentLocked, contentVideo, mentoring, isSpecialContent) {

    const self = this

    if (isSpecialContent == true) {
      if (self.objectivesFinished != true) {
        this.modalService.open(contentLocked, { centered: true, size: "md" });
      } else {
        if (mentoring.status == false) {
          this.app.alert('Se liga!', 'Este conteúdo estará disponível a partir do dia ' + mentoring.date, 'info')
        } else {
          this.urlToPlay = mentoring.videoUrl
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlToPlay);
          this.modalService.open(contentVideo, { centered: true, size: 'lg' });
        }
      }
    } else {

      if (self.elegiblePlan != true) {
        this.modalService.open(contentLocked, { centered: true, size: "md" });
      } else {
        if (mentoring.status == false) {
          this.app.alert('Se liga!', 'Esta mentoria acontecerá no dia ' + mentoring.date + '. Anote na sua agenda para não perder!', 'info')
        } else {
          this.urlToPlay = mentoring.videoUrl
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlToPlay);
          this.modalService.open(contentVideo, { centered: true, size: 'lg' });
        }
      }
    }
  }

  generateMentoringClass(mentoring) {

    const self = this

    if (self.elegiblePlan == true) {
      if (mentoring.status == true) {
        return 'video-thumbnail'
      } else {
        return 'video-thumbnail-next'
      }
    } else {
      return 'video-thumbnail-lock'
    }

  }

  generateSpecialContentClass(mentoring) {

    const self = this

    if (self.objectivesFinished == true) {
      if (mentoring.status == true) {
        return 'video-thumbnail'
      } else {
        return 'video-thumbnail-next'
      }
    } else {
      return 'video-thumbnail-lock'
    }

  }

  formatBrazilianDate(dateString) {
    const dateObject = new Date(dateString);
  
    const options = {
      year: 'numeric' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
    };
  
    return dateObject.toLocaleDateString('pt-BR', options);
  }

  formatDateWithoutTime(date){
    return moment(date).format('DD/MM/YYYY')
  }


}
