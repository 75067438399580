<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem;">Conta de Recebimento</h2>
    </div>
  </div>
</div>

<div class="row text-left mb-2">
  <div class="col-sm-12">
    <h5 class="text-bold-300">Está com dúvida sobre como cadastrar sua conta, veja o video abaixo</h5>
    <button class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton" (click)="OpenModalXl(contentVideo)"><i
        class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial</button>
  </div>
  <div class="col-sm-12 mt-2">  
    <a class="text-bold-500 mt-2" style="margin-top: 20px; font-size: 1.1em;" href="https://personaldigitalapp.gitbook.io/intermediacao-de-pagamentos/" target="_blank"> Taxas e Tarifas </a>
  </div>
</div>

<div class="row mb-2" *ngIf="(!iuguAccount.id || iuguAccount.id==null) && (subscription && subscription.plano_ativo==true)">
  <div class="col">
    <div class=" float-right">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openModal(newAccount)">Cadastrar
        Conta</button>
    </div>
  </div>
</div>
<div class="row" *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation!=3">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-12">
           
              <h2 class="text-bold-300"><i class="fa fa-clock-o"></i> Verificação de Identidade</h2>
              </div>
            </div>
              <div class="row mb-2">
                <div class="col-12">
                  <h5 *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation==1">Sua documentação está em análise pelo setor financeiro, agora é só aguardar! Assim que sua conta for validada, nós te avisaremos.</h5>
                  <p style="color:red;font-size: 1.3em;font-weight:500" *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation==2"><i class="fa fa-times"></i>Ops! Ocorreu um problema ao validar a sua documentação:</p>
                  <p style="font-size:1.1em" *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation==2"><i class="fa fa-info-circle"></i> {{iuguAccount.identity_validation_message}}</p>
                  </div>
                
              </div>
              <div class="row mb-2" *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation==2">
              <div class="col">
                <div class=" float-right">
                  <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openModal(resendDocuments)">Reenviar Documentos</button>
                </div>
              </div>
            </div>
          </div>

</div></div></div></div>

<div class="row" *ngIf="iuguAccount.id!=null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-8">
              <h2 class="text-bold-300"><i class="fa fa-user"></i> Dados Pessoais</h2>
            </div>
            <div class="col-4" *ngIf="!iuguAccount.can_receive">
              <div class=" float-right">
                <button type="button" class="btn btn-lg btn-warning  mb-md-0 "
                  (click)="verifyAccount(newAccount)">Solicitar Validação da Conta</button>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <b>Conta Apta para Recebimento:</b> {{iuguAccount.can_receive ? 'Sim' : 'Não'}}
            </div>
          </div>
          <div class="row mb-2" *ngIf="!iuguAccount.can_receive">
            <div class="col-12 col-lg-3 mb-1">
              <b>Status Verificação:</b> {{iuguAccount.verification_status && iuguAccount.verification_status ==
              'accepted' ? 'Aprovado' : 'Pendente'}}
            </div>
            <div class="col-12 col-lg-3" *ngIf="iuguAccount.verification_feedback">
              <b>Motivo:</b> {{iuguAccount.verification_feedback ? iuguAccount.verification_feedback : ''}}
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>Tipo:</b> {{iuguAccount.person_type == 0 ? "Pessoa Física" : "Pessoa Jurídica"}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>CPF/CNPJ:</b> {{iuguAccount.person_type == 0 ? iuguAccount.cpf : iuguAccount.cnpj}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Nome:</b> {{iuguAccount.person_type == 0 ? iuguAccount.person_name : iuguAccount.company_name}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Telefone:</b> {{iuguAccount.telephone}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="iuguAccount.id!=null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col">
              <h2 class="text-bold-300"><i class="fa fa-home"></i> Endereço</h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>CEP:</b> {{iuguAccount.cep}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Endereço:</b> {{iuguAccount.address}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Cidade:</b> {{iuguAccount.city}}
            </div>
            <div class="col-12 col-lg-3">
              <b>Estado:</b> {{iuguAccount.state}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="iuguAccount.id!=null">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col">
              <h2 class="text-bold-300"><i class="fa fa-bank"></i> Dados Bancários</h2>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <div class=" float-right">
                <button *ngIf="iuguAccount && iuguAccount.identity_validation && iuguAccount.identity_validation==3" type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openModal(newBank)">Alterar
                  Conta Bancária</button>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-lg-3 mb-1">
              <b>Banco:</b> {{iuguAccount.bank_name}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Tipo de Conta:</b> {{iuguAccount.bank_account_type}}
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <b>Agência:</b> {{iuguAccount.bank_agency}}
            </div>
            <div class="col-12 col-lg-3">
              <b>Conta:</b> {{iuguAccount.bank_account}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="iuguAccount.id==null && (subscription && subscription.plano_ativo==true)">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-0">
          <ul class="list-group p-0">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Você não tem uma conta cadastrada para receber pagamentos.</h5>
                  <span>Cadastre a sua conta de recebimento para poder utilizar o sistema de pagamentos O Personal
                    Digital, onde você vai poder receber o pagamento dos seus alunos referente às mensalidades da
                    consultoria, ou qualquer outro serviço ou produto que você queira. </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="iuguAccount.id==null && (subscription && subscription.plano_ativo==false)">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-0">
          <ul class="list-group p-0">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Essa função não está disponível na sua conta.</h5>
                  <span>Essa funcionalidade é apenas para assinantes. Para habilitá-la <a class="text-danger text-bold-500" href="https://app.opersonaldigital.com.br/pages/checkout" target="_blank" >clique aqui</a> e assine um plano.</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #newBank let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Cadastro Nova Conta Bancária</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #newContaForm="ngForm" class="editForm" novalidate>
          <br />
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="banco">Banco</label>
                  <ng-select [items]="bancos" bindLabel="banco" bindValue="codigo" name="banco" placeholder="Banco"
                    id="banco" [(ngModel)]="account.banco" #inputbanco="ngModel" (change)="verifyBankAgency()" required></ng-select>
                  <small class="form-text text-muted danger" *ngIf="inputbanco.errors?.required">Informe o banco</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="agencia">Agência</label>
                  <input type="text" [dropSpecialCharacters]="false" id="agencia" class="form-control" mask="0*"
                    [(ngModel)]="account.agencia" name="agencia" #inputagencia="ngModel" placeholder="Agência" required>
                  <small class="form-text text-muted danger" *ngIf="inputagencia.errors?.required">Informe a
                    agência</small>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="digitoagencia">Dígito Agência</label>
                  <input type="text" [dropSpecialCharacters]="false" id="digitoagencia" class="form-control" mask="0*"
                    [(ngModel)]="account.digito_agencia" name="digitoagencia" [disabled]="blockAgencyDigit" #inputdigitoagencia="ngModel"
                    placeholder="Dígito Agência">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="tipo_conta">Tipo de Conta</label>
                  <ng-select [items]="tipoconta" bindLabel="descricao" bindValue="codigo" name="tipo_conta"
                    placeholder="Tipo de Conta" id="tipo_conta" [(ngModel)]="account.tipo_conta"
                    #inputtipoconta="ngModel" required></ng-select>
                  <small class="form-text text-muted danger" *ngIf="inputtipoconta.errors?.required">Informe o tipo de
                    conta</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="conta">Conta</label>
                  <input type="text" id="conta" [dropSpecialCharacters]="false" class="form-control" mask="0*"
                    [(ngModel)]="account.conta" name="conta" #inputconta="ngModel" placeholder="Conta" required>
                  <small class="form-text text-muted danger" *ngIf="inputconta.errors?.required">Informe a conta</small>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="digitoconta">Dígito Conta</label>
                  <input type="text" id="digitoconta" [dropSpecialCharacters]="false" class="form-control" mask="0*"
                    [(ngModel)]="account.digito_conta" name="digitoconta" #inputdigitoconta="ngModel"
                    placeholder="Dígito Conta" [disabled]="blockAccountDigit">
                  <!-- <small class="form-text text-muted danger" *ngIf="inputdigitoconta.errors?.required">Informe o dígito
                    da conta</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-lg btn-danger mr-2"
                (click)="modal.close('Close click')">Cancelar</button>
              <button type="button" class="btn btn-lg btn-warning" (click)="updateBank(modal)"
                [disabled]="!newContaForm.valid">Atualizar Conta Bancária</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #resendDocuments let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Enviar Documento de Indetificação</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step [stepTitle]="'Envio de Documentos'"
          [navigationSymbol]="{ symbol: '&#xf2c2;', fontFamily: 'FontAwesome' }">
          <form #identificacaoForm="ngForm" class="editForm" novalidate>
            <br />
            <div class="form-row mb-4">
              <div class="col-12 col-lg-12 text-center mb-2">
                <img class="mt-2 mb-2" *ngIf="identityFrontImageURL" [src]="identityFrontImageURL"
                style="margin-left: auto; margin-right: auto; max-width: 350px;">
                <button type="button" class="btn btn-lg gradient-mint" (click)="selecionarImagemFrenteDocumento()"
                  style="width: 100%" placement="top">Foto Frontal do Documento</button>
               
              </div>
              <div class="col-12 col-lg-12 text-center mb-2">
                <img class="mt-2 mb-2" *ngIf="identityBackImageURL" [src]="identityBackImageURL"
                  style="margin-left: auto; margin-right: auto; max-width: 350px;">
                <button type="button" class="btn btn-lg gradient-mint" (click)="selecionarImagemVersoDocumento()"
                  style="width: 100%" placement="top">Foto do Verso do Documento</button>
                
              </div>
            </div>

            <div class="form-row mb-4">
              <div class="col-12 col-lg-12 text-center mb-2">
                <img class="mt-2 mb-2" *ngIf="selfieImageURL" [src]="selfieImageURL"
                  style="margin-left: auto; margin-right: auto; max-width: 350px;">
                <button type="button" class="btn btn-lg gradient-mint" (click)="OpenModalNormal(takepicture)"
                  style="width: 100%" placement="top">Selfie</button>
                <img class="mt-2" *ngIf="selfireImageURL" [src]="selfireImageURL"
                  style="margin-left: auto; margin-right: auto; max-width: 350px;">
              </div>

            </div>




            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-warning" (click)="resendIdentityVerification(modal)"
                  [disabled]="!identityFrontImage || !identityBackImage || !selfieImage" awNextStep>Reenviar Validação</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>
</ng-template>

<ng-template #newAccount let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Cadastro Conta Recebimento</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step [stepTitle]="'Tipo de Conta'"
            [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
            <form #firstForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-group">
                <label>Você vai receber pagamentos como pessoa física ou pessoa jurídica?</label>
                <div class="col p-0">
                  <ul class="list-unstyled mb-0">
                    <li class="d-inline-block mr-2" style="vertical-align: middle">
                      <div class="radio radio-sm">
                        <input type="radio" name="size-radio-1" [value]="true" id="size-sm"
                          (click)="switchTypeAccount(true)" [(ngModel)]="isCPF">
                        <label for="size-sm">Pessoa Física</label>
                      </div>
                    </li>
                    <li class="d-inline-block" style="vertical-align: middle">
                      <div class="radio radio-sm">
                        <input type="radio" name="size-radio-1" [value]="false" id="size-default"
                          (click)="switchTypeAccount(false)" [(ngModel)]="isCPF">
                        <label for="size-default">Pessoa Jurídica</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group mb-2">
                  <label for="grupoSelecionado">Vende Produtos Físicos?</label>
                  <ui-switch checked class="switchery" placement="top"
                    ngbTooltip="Você comercializará produtos físicos?" name="physical_products"
                    [(ngModel)]="account.physical_products"></ui-switch>
                </div>
              </div>
              <div class="form-row" *ngIf="isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pf_cpf">CPF</label>
                    <input type="text" mask="000.000.000-00" id="pf_cpf" class="form-control" name="pf_cpf"
                      [(ngModel)]="account.pf_cpf" #inputCPF="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputCPF.errors?.required">Informe o seu
                      CPF</small>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pf_nome">Nome Completo</label>
                    <input type="text" id="pf_nome" class="form-control" name="pf_nome" [(ngModel)]="account.pf_nome"
                      #inputNomeCompleto="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Informe o seu
                      nome completo</small>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_cnpj">CNPJ</label>
                    <input type="text" mask="00.000.000/0000-00" id="pj_cnpj" class="form-control" name="pj_cnpj"
                      [(ngModel)]="account.pj_cnpj" #inputCNPJ="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputCNPJ.errors?.required">Informe o seu
                      CNPJ</small>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_nome">Nome da Empresa</label>
                    <input type="text" id="pj_nome" class="form-control" name="pj_nome" [(ngModel)]="account.pj_nome"
                      #inputPJNOME="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputPJNOME.errors?.required">Informe o nome da
                      empresa</small>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_cpf_responsavel">CPF Responsável</label>
                    <input type="text" mask="000.000.000-00" id="pj_cpf_responsavel" class="form-control"
                      name="pj_cpf_responsavel" [(ngModel)]="account.pj_cpf_responsavel" #inputCPFPJ="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputCPFPJ.errors?.required">Informe o CPF do
                      responsável</small>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="!isCPF">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="pj_nome_responsavel">Nome Completo Responsável</label>
                    <input type="text" id="pj_nome_responsavel" class="form-control" name="pj_nome_responsavel"
                      [(ngModel)]="account.pj_nome_responsavel" #inputNomeCompletoPJ="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputNomeCompletoPJ.errors?.required">Informe o
                      nome completo do responsável</small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-danger mr-2"
                    (click)="modal.close('Close click')">Cancelar</button>
                  <button type="button" class="btn btn-lg btn-success" [disabled]="!firstForm.valid" awNextStep>Próximo
                    Passo</button>
                </div>
              </div>
            </form>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="'Endereço e Contato'"
            [navigationSymbol]="{ symbol: '&#xf124;', fontFamily: 'FontAwesome' }">
            <form #addressForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cep">CEP</label>
                      <input type="text" id="cep" class="form-control" [(ngModel)]="account.cep" name="nome"
                        mask="00000-000" placeholder="Cep" (change)="consultaCep()" #inputCep="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputCep.errors?.required">Informe o Cep</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="street">Rua/Avenida</label>
                      <input type="text" id="street" class="form-control" [(ngModel)]="account.endereco" name="street"
                        #inputEndereco="ngModel" placeholder="Rua/Avenida" required>
                      <small class="form-text text-muted danger" *ngIf="inputEndereco.errors?.required">Informe a
                        rua</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="district">Bairro</label>
                      <input type="text" id="district" class="form-control" [(ngModel)]="account.bairro" name="bairro"
                        placeholder="Bairro">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-8 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="city">Cidade</label>
                      <input type="text" id="city" class="form-control" [(ngModel)]="account.cidade" name="city"
                        #inputcidade="ngModel" placeholder="Cidade" required>
                      <small class="form-text text-muted danger" *ngIf="inputcidade.errors?.required">Informe a
                        cidade</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="state">Estado</label>
                      <ng-select [items]="estados" name="state" placeholder="Estado" id="state"
                        [(ngModel)]="account.estado" #inputestado="ngModel" required></ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputestado.errors?.required">Informe o
                        estado</small>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="phone">Telefone</label>
                      <ngx-intl-tel-input [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Brazil" [maxLength]="15"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National" name="phone"
                        searchCountryPlaceholder="Selecione o País" [(ngModel)]="account.telefone" required>
                      </ngx-intl-tel-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-light mr-2" awPreviousStep>Voltar</button>
                  <button type="button" class="btn btn-lg btn-success" [disabled]="!addressForm.valid"
                    awNextStep>Próximo Passo</button>
                </div>
              </div>
            </form>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="'Conta Bancária'"
            [navigationSymbol]="{ symbol: '&#xf155;', fontFamily: 'FontAwesome' }">
            <form #contaForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="banco">Banco</label>
                      <ng-select [items]="bancos" bindLabel="banco" bindValue="banco" name="banco" placeholder="Banco"
                        id="banco" [(ngModel)]="account.banco" #inputbanco="ngModel" (change)="verifyBankAgency()" required></ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputbanco.errors?.required">Informe o
                        banco</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="agencia">Agência</label>
                      <input type="text" [dropSpecialCharacters]="false" id="agencia" class="form-control" mask="0*"
                        [(ngModel)]="account.agencia" name="agencia" #inputagencia="ngModel" placeholder="Agência"
                        required>
                      <small class="form-text text-muted danger" *ngIf="inputagencia.errors?.required">Informe a
                        agência</small>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="digitoagencia">Dígito Agência</label>
                      <input type="text" [dropSpecialCharacters]="false" id="digitoagencia" class="form-control"
                        [(ngModel)]="account.digito_agencia" name="digitoagencia" [disabled]="blockAgencyDigit"
                        #inputdigitoagencia="ngModel" placeholder="Dígito Agência">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_conta">Tipo de Conta</label>
                      <ng-select [items]="tipoconta" bindLabel="descricao" bindValue="descricao" name="tipo_conta"
                        placeholder="Tipo de Conta" id="tipo_conta" [(ngModel)]="account.tipo_conta"
                        #inputtipoconta="ngModel" required></ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputtipoconta.errors?.required">Informe o tipo
                        de conta</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="conta">Conta</label>
                      <input type="text" id="conta" [dropSpecialCharacters]="false" class="form-control" mask="0*"
                        [(ngModel)]="account.conta" name="conta" #inputconta="ngModel" placeholder="Conta" required>
                      <small class="form-text text-muted danger" *ngIf="inputconta.errors?.required">Informe a
                        conta</small>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="digitoconta">Dígito Conta</label>
                      <input type="text" id="digitoconta" [dropSpecialCharacters]="false" class="form-control" mask="0*"
                        [(ngModel)]="account.digito_conta" name="digitoconta" #inputdigitoconta="ngModel"
                        placeholder="Dígito Conta" [disabled]="blockAccountDigit" required>
                      <small class="form-text text-muted danger" *ngIf="inputdigitoconta.errors?.required">Informe o
                        dígito da conta</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <div class="col-12">
                  <i class="ft ft-check-square"></i><small>Ao cadastrar sua conta de recebimentos você estará de acordo
                    com os termos de uso e <a href="https://personaldigitalapp.gitbook.io/intermediacao-de-pagamentos/"
                      target="_blank">tarifas vigentes</a></small>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-light mr-2" awPreviousStep>Voltar</button>
                  <button type="button" class="btn btn-lg btn-success" [disabled]="!contaForm.valid" awNextStep>Próximo
                    Passo</button>
                </div>
              </div>
            </form>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="'Envio de Documentos'"
            [navigationSymbol]="{ symbol: '&#xf2c2;', fontFamily: 'FontAwesome' }">
            <form #identificacaoForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row mb-4">
                <div class="col-12 col-lg-12 text-center mb-2">
                  <img class="mt-2 mb-2" *ngIf="identityFrontImageURL" [src]="identityFrontImageURL"
                  style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  <button type="button" class="btn btn-lg gradient-mint" (click)="selecionarImagemFrenteDocumento()"
                    style="width: 100%" placement="top">Foto Frontal do Documento</button>
                 
                </div>
                <div class="col-12 col-lg-12 text-center mb-2">
                  <img class="mt-2 mb-2" *ngIf="identityBackImageURL" [src]="identityBackImageURL"
                    style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  <button type="button" class="btn btn-lg gradient-mint" (click)="selecionarImagemVersoDocumento()"
                    style="width: 100%" placement="top">Foto do Verso do Documento</button>
                  
                </div>
              </div>

              <div class="form-row mb-4">
                <div class="col-12 col-lg-12 text-center mb-2">
                  <img class="mt-2 mb-2" *ngIf="selfieImageURL" [src]="selfieImageURL"
                    style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  <button type="button" class="btn btn-lg gradient-mint" (click)="OpenModalNormal(takepicture)"
                    style="width: 100%" placement="top">Selfie</button>
                  <img class="mt-2" *ngIf="selfireImageURL" [src]="selfireImageURL"
                    style="margin-left: auto; margin-right: auto; max-width: 350px;">
                </div>

              </div>




              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-light mr-2" awPreviousStep>Voltar</button>
                  <button type="button" class="btn btn-lg btn-warning" (click)="submitAccount(modal)"
                    [disabled]="!identityFrontImage || !identityBackImage || !selfieImage" awNextStep>Cadastrar Conta</button>
                </div>
              </div>
            </form>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentVideo>
  <!-- <video controls style="width: 100%;">
    <source [src]="'https://files.wecodde.com/file/wecodde/01+-+Cadastro+de+dados+de+conta.mp4'" type="video/mp4">
  </video> -->
  <div class='embed-container'><iframe  [src]="videoUrl" frameborder='0' allowfullscreen></iframe></div>
</ng-template>
<ng-template #takepicture let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Envio de Selfie</h4>
    </div>
    <div class="card-content">
      <div class="card-body">

        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step [stepTitle]="'Instruções'"
            [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">


          <h4>Dicas para sua foto ser aprovada:</h4>
            <ul style="color: red;">
              <li>Tire a foto em um local iluminado</li>
              <li>Não utilize boné ou óculos, não sorria</li>
              <li>Posicione o documento de forma que fique legível</li>
            </ul>

            <div class="col text-center">
            <p>Exemplo de como deve ser a foto:</p>
            <img src="https://files.wecodde.com/file/wecodde/instrucao_selfie.png"
              style="width: 200px;margin-left: auto; margin-right: auto;">
              </div>


            <div class="form-row mt-2">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-success" awNextStep>Tirar Selfie</button>
              </div>
            </div>
      </aw-wizard-step>

      <aw-wizard-step [stepTitle]="'Envio da Imagem'"
        [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
        <div class="row mb-2" id="print-section">
          <div class="col text-center">
       
            
            

                <webcam [height]="600" [width]="400" [trigger]="triggerObservable"
                  (imageCapture)="handleImage($event, modal)" [imageQuality]="1" [allowCameraSwitch]="true"
                  >
                </webcam>
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 btn-block"
                  (click)="triggerSnapshot()">Tirar Foto</button>
              </div>
            </div>
                  
      </aw-wizard-step>
      </aw-wizard>
      </div></div></div>
</ng-template>
<ngx-spinner></ngx-spinner>