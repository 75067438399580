<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Indique e Ganhe
      </h2>
    </div>
  </div>
</div>

<div class="row text-left mb-2">
  <div class="col-sm-12">
    <h5 class="text-bold-300">
      {{
        myCodeLink
          ? "Está com alguma dúvida? Entenda tudo sobre o indique e ganhe neste vídeo"
          : "Veja esse vídeo antes de começar"
      }}
    </h5>
    <button
      class="btn btn-warning border-right-warning mb-2 mb-md-0"
      (click)="OpenModalXl(contentVideo)"
    >
      <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
    </button>
  </div>
</div>

<div class="row mb-3">
  <div class="col-12">
    <div
      class="d-flex flex-column w-100 justify-content-center align-items-center"
    >
      <h3 class="black text-center text-bold-400 font-medium-3">
        Convide um novo <b>personal</b> e ganhe até
      </h3>
      <div class="d-flex flex-column align-items-center gap-2 mb-2">
        <div class="d-flex align-items-center black">
          <span class="text-bold-500 font-medium-3 mr-2">R$</span>
          <h3 class="text-primary font-large-2 text-bold-700 mb-1">100,00</h3>
        </div>
        <span>(por convidado!)</span>
      </div>
      <span class="badge badge-pill badge-light px-2 badge-text"
        >Se ele(a) se cadastrar com seu link e assinar Plano</span
      >
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-header">
          <div class="d-flex w-100 justify-content-center align-items-center">
            <h4 class="font-small-3">PARA INDICAR, É SIMPLES!</h4>
          </div>
        </div>
        <div class="card-body d-flex flex-column align-items-center">
          <div
            class="w-100 d-flex flex-row align-items-start justify-content-around overflow-hidden mb-2"
          >
            <div
              class="nominate-icon-container d-flex flex-column justify-content-center align-items-center position-relative"
            >
              <div class="position-absolute h-1px bg-light custom-line"></div>
              <div class="mb-2 rounded-pill z-2">
                <img
                  src="../../../../assets/img/nominate_cel.svg"
                  alt="Icone que representa um convite"
                  class="img-fluid"
                />
              </div>
              <span class="black font-small-2 text-center nominate-icon-label"
                >Convide um(a) personal</span
              >
            </div>
            <div
              class="nominate-icon-container d-flex flex-column justify-content-center align-items-center"
            >
              <div class="mb-2 rounded-pill">
                <img
                  src="../../../../assets/img/nominate_forms.svg"
                  alt="Icone que representa um cadastro concluído"
                  class="img-fluid"
                />
              </div>
              <span class="black font-small-2 text-center nominate-icon-label"
                >Ele(a) se cadastra com seu link</span
              >
            </div>
            <div
              class="nominate-icon-container d-flex flex-column justify-content-center align-items-center position-relative"
            >
              <div class="position-absolute bg-white custom-right-line"></div>
              <div class="mb-2 rounded-pill z-2">
                <img
                  src="../../../../assets/img/nominate_money.svg"
                  alt="Icone que representa uma recompensa"
                  class="img-fluid"
                />
              </div>
              <span class="black font-small-2 text-center nominate-icon-label"
                >Se assinar plano conosco, ganhe até R$ 100,00</span
              >
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <span
              class="badge badge-pill badge-primary black badge-text px-2 text-bold-300"
              ><b>Ilimitado!</b> Quanto <b>mais</b> indicar, <b>maior</b> a
              recompensa!</span
            >
          </div>
        </div>
        <div class="card-footer p-0 border-top border-light">
          <button
            type="button"
            (click)="scrollToId('tabs')"
            class="btn w-100 black font-small-2 py-1 d-flex align-items-center justify-content-center"
          >
            Regras Detalhadas
            <i class="ml-1 ft-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="
    myCodeLink &&
      (indicacoes.length > 0 ||
        faturasMes.length > 0 ||
        formatPreco(saldoLiberado) !== 'R$ 0,00' ||
        formatPreco(saldoPendente) !== 'R$ 0,00');
    else noNominates
  "
>
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">{{ indicacoes.length }}</h3>
              <span
                >Indicações
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="As indicações são personais que se cadastraram na plataforma através do seu link de indicação"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-user warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">{{ faturasMes.length }}</h3>
              <span
                >Assinantes no mês atual
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Quantidade de personais indicados por você que assinaram a plataforma no mês atual"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-pie-chart warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 success">{{ formatPreco(saldoLiberado) }}</h3>
              <span
                >Saldo Disponível
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="O Saldo disponível para saque, os pagamentos são realizados sempre no dia 10 de cada mês subsequente a liberação"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-dollar-sign success font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 secondary">{{ formatPreco(saldoPendente) }}</h3>
              <span
                >Saldo Pendente
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="O Saldo pendente é o valor que está em validação de pagamento e será liberado 30 dias após o pagamento."
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-clock secondary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noNominates>
  <div class="row black">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div
            class="card-header d-flex justify-content-center align-items-start"
          >
            <h3 class="font-small-3 text-bold-500 m-0">HISTÓRICO</h3>
          </div>
          <div
            class="card-body d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start position-relative"
          >
            <div class="no-message-icon">
              <img
                src="../../../../assets/img/no_message_illustration.svg"
                alt="Icone que representa a falta de mensagens"
                class="img-fluid"
              />
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <span class="mb-2 text-center font-small-2 text-bold-400"
                >Nenhum convite encontrado. Convide personais para ativar
                recompensas! </span
              ><span
                class="text-center text-bold-400 font-small-1 text-secondary"
                >Atenção! Seu convidado precisa se cadastrar usando seu link de
                convite!</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div id="tabs" class="row text-left" *ngIf="myCodeLink">
  <!--Pills Starts-->
  <div class="col-md-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <ngb-tabset type="pills">
            <ngb-tab title="Prêmios">
              <ng-template ngbTabContent>
                <section
                  id="vertical-timeline-center"
                  class="timeline-center timeline-wrapper col-sm-10 col-12 offset-sm-1"
                >
                  <!--Ebook 01-->
                  <ul class="timeline">
                    <li class="timeline-line mt-4"></li>
                    <li class="timeline-group">
                      <h5>
                        <span class="badge badge-primary cursor-default"
                          >Seus Prêmios</span
                        >
                      </h5>
                    </li>
                  </ul>
                  <ul class="timeline">
                    <li class="timeline-line"></li>
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-primary bg-lighten-4"
                          data-toggle="tooltip"
                          placement="left"
                          ngbTooltip="Como Montar sua Consultoria Online do Zero"
                        >
                          <i class="ft-check-circle primary"></i>
                        </span>
                      </div>
                      <div class="timeline-card card shadow">
                        <div class="card-header">
                          <img
                            class="img-fluid"
                            src="https://files.wecodde.com/file/wecodde/ebook_1.png"
                            alt="Como Montar Consultoria Online"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <h4 class="card-title mb-2">
                              Como Montar sua Consultoria Online do Zero
                            </h4>

                            <hr class="mt-0 mb-2" />
                            <p class="m-0">
                              Monte sua Consultoria Online do Zero e Transformar
                              o Corre Corre das Academias em Centenas de Vendas
                              Online Todos os Dias.
                            </p>
                            <hr class="my-2" />
                            <div class="col-12 text-center">
                              <h5 class="m-2 text-danger" *ngIf="isVisible1">
                                <i class="fa fa-spinner fa-spin"></i> Aguarde,
                                download em andamento!
                              </h5>
                              <button
                                type="button"
                                class="btn btn-lg btn-warning"
                                (click)="downloadItem(1)"
                              >
                                <i class="fa fa-download"></i> Baixar Agora
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="timeline-item mt-5">
                      <div class="timeline-badge">
                        <span
                          class="bg-primary bg-lighten-4"
                          data-toggle="tooltip"
                          placement="right"
                          ngbTooltip="12 Passos Instagram"
                        >
                          <i class="ft-check-circle primary"></i>
                        </span>
                      </div>
                      <div class="timeline-card card shadow">
                        <div class="card-header">
                          <img
                            class="img-fluid"
                            src="https://files.wecodde.com/file/wecodde/ebook_2.png"
                            alt="Ebook Instagram"
                            [ngStyle]="{
                              filter:
                                indicacoes.length == 0
                                  ? 'grayscale(100%)'
                                  : 'grayscale(0%)'
                            }"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <h4 class="card-title mb-2">
                              12 Passos Para o Personal Trainer Conquistar +
                              Alunos e Bombar no Instagram
                            </h4>
                            <hr class="mt-0 mb-2" />
                            <p class="m-0">
                              Transforme seu Instagram numa PODEROSA Ferramenta
                              de Atração e Captação de Alunos Pagantes de
                              Personal
                            </p>
                            <hr class="my-2" />
                            <div class="col-12 text-center">
                              <h5
                                class="text-danger m-0"
                                *ngIf="indicacoes.length == 0"
                              >
                                <i class="fa fa-lock"></i> Recompensa Bloqueada
                              </h5>
                              <p
                                class="text-bold-600 m-2 text-danger"
                                *ngIf="indicacoes.length == 0"
                              >
                                Falta 1 indicação para você ter acesso a esta
                                recompensa
                              </p>
                              <h5 class="m-2 text-danger" *ngIf="isVisible2">
                                <i class="fa fa-spinner fa-spin"></i> Aguarde,
                                download em andamento!
                              </h5>
                              <button
                                type="button"
                                class="btn btn-lg btn-warning"
                                (click)="downloadItem(2)"
                                *ngIf="indicacoes.length > 0"
                              >
                                <i class="fa fa-download"></i> Baixar Agora
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-primary bg-lighten-4"
                          data-toggle="tooltip"
                          placement="right"
                          ngbTooltip="Grupos de Desafio Lucrativos"
                        >
                          <i class="ft-check-circle primary"></i>
                        </span>
                      </div>
                      <div class="timeline-card card shadow">
                        <div class="card-header">
                          <img
                            class="img-fluid"
                            src="https://files.wecodde.com/file/wecodde/ebook_3.png"
                            alt="Grupos de Desafio Lucrativos"
                            [ngStyle]="{
                              filter:
                                indicacoes.length < 5
                                  ? 'grayscale(100%)'
                                  : 'grayscale(0%)'
                            }"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <h4 class="card-title mb-2">
                              Livro Digital Grupos de Desafio Lucrativos
                            </h4>

                            <hr class="mt-0 mb-2" />
                            <p class="m-0">
                              Tenha acesso ao manual que revela os bastidores de
                              quem fatura 5 dígitos com o WhatsApp de forma
                              simples e descomplicada.
                            </p>
                            <hr class="my-2" />
                            <div class="col-12 text-center">
                              <h5
                                class="text-danger m-0"
                                *ngIf="indicacoes.length < 5"
                              >
                                <i class="fa fa-lock"></i> Recompensa Bloqueada
                              </h5>
                              <p
                                class="text-bold-600 m-2 text-danger"
                                *ngIf="indicacoes.length < 5"
                              >
                                Faltam {{ 5 - indicacoes.length }} indicações
                                para você ter acesso a esta recompensa
                              </p>
                              <h5 class="m-2 text-danger" *ngIf="isVisible3">
                                <i class="fa fa-spinner fa-spin"></i> Aguarde,
                                download em andamento!
                              </h5>
                              <button
                                type="button"
                                class="btn btn-lg btn-warning"
                                (click)="downloadItem(3)"
                                *ngIf="indicacoes.length >= 5"
                              >
                                <i class="fa fa-download"></i> Baixar Agora
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="timeline-item mt-5">
                      <div class="timeline-badge">
                        <span
                          class="bg-primary bg-lighten-4"
                          data-toggle="tooltip"
                          placement="right"
                          ngbTooltip="Planilha Financeira"
                        >
                          <i class="ft-check-circle primary"></i>
                        </span>
                      </div>
                      <div class="timeline-card card shadow">
                        <div class="card-header">
                          <img
                            class="img-fluid"
                            src="https://files.wecodde.com/file/wecodde/ebook_4.png"
                            alt="Planilha Financeira"
                            [ngStyle]="{
                              filter:
                                indicacoes.length < 5
                                  ? 'grayscale(100%)'
                                  : 'grayscale(0%)'
                            }"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <h4 class="card-title mb-2">
                              Planilha Planejamento Financeiro
                            </h4>

                            <hr class="mt-0 mb-2" />
                            <p class="m-0">
                              Organize suas Finanças, Gerencie as Despesas e
                              Descubra se Você está Tendo Lucro ou Prejuízo nos
                              Serviços de Personal.
                            </p>
                            <hr class="my-2" />
                            <div class="col-12 text-center">
                              <h5
                                class="text-danger m-0"
                                *ngIf="indicacoes.length < 5"
                              >
                                <i class="fa fa-lock"></i> Recompensa Bloqueada
                              </h5>
                              <p
                                class="text-bold-600 m-2 text-danger"
                                *ngIf="indicacoes.length < 5"
                              >
                                Faltam {{ 5 - indicacoes.length }} indicações
                                para você ter acesso a esta recompensa
                              </p>
                              <h5 class="m-2 text-danger" *ngIf="isVisible4">
                                <i class="fa fa-spinner fa-spin"></i> Aguarde,
                                download em andamento!
                              </h5>
                              <button
                                type="button"
                                class="btn btn-lg btn-warning"
                                (click)="downloadItem(4)"
                                *ngIf="indicacoes.length >= 5"
                              >
                                <i class="fa fa-download"></i> Baixar Agora
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-primary bg-lighten-4"
                          data-toggle="tooltip"
                          placement="right"
                          ngbTooltip="Imersão A Virada"
                        >
                          <i class="ft-check-circle primary"></i>
                        </span>
                      </div>
                      <div class="timeline-card card shadow">
                        <div class="card-header">
                          <img
                            class="img-fluid"
                            src="https://f000.backblazeb2.com/file/wecodde/ebook_5.png"
                            alt="Timeline Image 1"
                            [ngStyle]="{
                              filter:
                                indicacoes.length < 5
                                  ? 'grayscale(100%)'
                                  : 'grayscale(0%)'
                            }"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <h4 class="card-title mb-2">Imersão A Virada</h4>

                            <hr class="mt-0 mb-2" />
                            <p class="m-0">
                              O Método para faturar R$ 10.000 por mês (ou mais)
                            </p>
                            <hr class="my-2" />
                            <div class="col-12 text-center">
                              <h5
                                class="text-danger m-0"
                                *ngIf="indicacoes.length < 5"
                              >
                                <i class="fa fa-lock"></i> Recompensa Bloqueada
                              </h5>
                              <p
                                class="text-bold-600 m-2 text-danger"
                                *ngIf="indicacoes.length < 5"
                              >
                                Faltam {{ 5 - indicacoes.length }} indicações
                                para você ter acesso a esta recompensa
                              </p>
                              <a
                                class="btn btn-lg btn-warning"
                                style="align-content: center"
                                href="https://formulados5digitos.club.hotmart.com/login"
                                target="_blank"
                                *ngIf="indicacoes.length >= 5"
                                ><i class="fa fa-sign-in"></i> Acessar Agora</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </section>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Indicações">
              <ng-template ngbTabContent>
                <div class="row mb-2 mt-2" *ngIf="indicacoes.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <section
                          id="vertical-timeline-left"
                          class="timeline-left timeline-wrapper col-sm-10 col-12 offset-sm-1"
                        >
                          <ul class="timeline">
                            <li class="timeline-line mt-4"></li>
                            <li class="timeline-group"></li>
                          </ul>
                          <ul class="timeline">
                            <li class="timeline-line"></li>
                            <li
                              class="timeline-item"
                              *ngFor="let indicacao of indicacoes"
                            >
                              <div class="timeline-badge">
                                <span
                                  class="bg-primary bg-lighten-4"
                                  data-toggle="tooltip"
                                  placement="right"
                                  ngbTooltip="{{
                                    formatDate(indicacao.createdAt)
                                  }}"
                                >
                                  <i class="ft-calendar primary"></i>
                                </span>
                              </div>
                              <div class="timeline-card card shadow">
                                <div class="card-content">
                                  <div class="card-body">
                                    <h4 class="card-title mb-0">
                                      <i class="fa fa-user"></i>
                                      {{ indicacao.name }}
                                    </h4>
                                    <div class="card-subtitle mt-0">
                                      <span class="font-small-3"
                                        ><i class="fa fa-calendar"></i> Data de
                                        Cadastro:
                                        {{
                                          formatDate(indicacao.createdAt)
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2 mt-2" *ngIf="indicacoes.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <p>Até o momento você não tem indicados =(</p>
                        <p>
                          Indique o O Personal Digital usando seu link de
                          indicação e ganhe prêmios.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Cashback">
              <ng-template ngbTabContent>
                <div class="row mb-2 mt-2" *ngIf="faturas.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="form-row">
                          <div class="col-3">
                            <div class="form-group mb-2">
                              <label for="tipotreino">Selecione o Mês</label>
                              <ng-select
                                [notFoundText]="'Nenhum tipo encontrado'"
                                [items]="meses"
                                bindLabel="name"
                                name="model"
                                [(ngModel)]="mesSelecionado"
                                placeholder="Selecionar Mês"
                                id="mes"
                                (ngModelChange)="toggleDate()"
                              ></ng-select>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group mb-2">
                              <label for="tipotreino">Selecione o Ano</label>
                              <ng-select
                                [notFoundText]="'Nenhum tipo encontrado'"
                                [items]="anos"
                                bindLabel="name"
                                name="model"
                                [(ngModel)]="anoSelecionado"
                                placeholder="Selecionar Ano"
                                id="ano"
                                (ngModelChange)="toggleDate()"
                              ></ng-select>
                            </div>
                          </div>
                        </div>
                        <section
                          id="vertical-timeline-left"
                          class="timeline-left timeline-wrapper col-sm-10 col-12 offset-sm-1"
                        >
                          <ul class="timeline">
                            <li class="timeline-line mt-4"></li>
                            <li class="timeline-group"></li>
                          </ul>
                          <ul class="timeline">
                            <li class="timeline-line"></li>
                            <li
                              class="timeline-item"
                              *ngFor="let invoice of faturasFiltered"
                            >
                              <div class="timeline-badge">
                                <span
                                  class="bg-primary bg-lighten-4"
                                  data-toggle="tooltip"
                                  placement="right"
                                  ngbTooltip="{{ formatDate(invoice.paidAt) }}"
                                >
                                  <i class="ft-calendar primary"></i>
                                </span>
                              </div>
                              <div class="timeline-card card shadow">
                                <div class="card-content">
                                  <div class="card-body">
                                    <h4 class="card-title mb-0">
                                      {{ invoice.userName }}
                                    </h4>
                                    <div class="card-subtitle text-muted mt-0">
                                      <span class="font-small-3">{{
                                        formatDate(invoice.invoicePaymentDate)
                                      }}</span>
                                    </div>
                                    <hr class="mt-0 mb-2" />
                                    <p class="mb-1">
                                      <i class="ft-check-square mr-1"></i
                                      ><b>Plano:</b>
                                      {{ formatPlan(invoice.planIdentifier) }}
                                    </p>
                                    <p class="m-0">
                                      <i class="ft-dollar-sign mr-1"></i
                                      ><b>Comissão:</b>
                                      {{ formatPreco(invoice.commissionValue) }}
                                    </p>

                                    <hr class="my-2" />
                                    <div class="list-inline">
                                      <span
                                        class="badge bg-light-success mb-1 mr-1"
                                        *ngIf="invoice.paymentId != null"
                                        ><i class="ft-dollar-sign mr-1"></i
                                        >Paga</span
                                      >
                                      <span
                                        class="badge bg-light-success mb-1 mr-1"
                                        [ngClass]="
                                          verificarComissaoLiberada(
                                            invoice.invoicePaymentDate
                                          )
                                            ? 'bg-light-success'
                                            : 'bg-light-warning'
                                        "
                                        *ngIf="invoice.pagamentoId == null"
                                        ><i
                                          class="mr-1"
                                          [ngClass]="
                                            verificarComissaoLiberada(
                                              invoice.invoicePaymentDate
                                            )
                                              ? 'ft-check'
                                              : 'ft-clock'
                                          "
                                        ></i
                                        >{{
                                          verificarComissaoLiberada(
                                            invoice.invoicePaymentDate
                                          )
                                            ? "Confirmada"
                                            : "Pendente"
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2 mt-2" *ngIf="faturas.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <p>Até o momento você não ganhou nenhum cashback =(</p>
                        <p>
                          Indique o O Personal Digital usando seu link de
                          indicação, que você receberá cashback de todo personal
                          que assinar um plano.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Conta Bancária">
              <ng-template ngbTabContent>
                <form #exForm="ngForm" class="editForm" novalidate>
                  <div class="form-row">
                    <div class="col-md-6 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Nome do Titular</label>
                        <input
                          type="text"
                          id="accountHolder"
                          class="form-control"
                          name="accountHolder"
                          [(ngModel)]="bankAccount.accountHolder"
                          #inputName="ngModel"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group mb-2">
                        <label for="name">CPF do Titular</label>
                        <input
                          type="text"
                          id="userCpf"
                          class="form-control"
                          name="userCpf"
                          [(ngModel)]="bankAccount.userCpf"
                          #inputName="ngModel"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-md-3 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Banco</label>
                        <input
                          type="text"
                          id="bank"
                          class="form-control"
                          name="bank"
                          [(ngModel)]="bankAccount.bank"
                          #inputName="ngModel"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Agência</label>
                        <input
                          type="text"
                          id="agency"
                          class="form-control"
                          name="agency"
                          [(ngModel)]="bankAccount.agency"
                          #inputName="ngModel"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Conta</label>
                        <input
                          type="text"
                          id="account"
                          class="form-control"
                          name="account"
                          [(ngModel)]="bankAccount.account"
                          #inputName="ngModel"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Tipo de Conta</label>
                        <select
                          id="accountType"
                          name="accountType"
                          [(ngModel)]="bankAccount.accountType"
                          class="form-control"
                          required
                        >
                          <option value="1">Corrente</option>
                          <option value="2">Poupança</option>
                          <option value="3">Conta de Pagamento</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-md-4 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Chave PIX</label>
                        <input
                          type="text"
                          id="accountPix"
                          class="form-control"
                          name="accountPix"
                          [(ngModel)]="bankAccount.accountPix"
                          #inputName="ngModel"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-12">
                      <div class="form-group mb-2">
                        <label for="name">Tipo de Chave PIX</label>
                        <select
                          id="accountPixKey"
                          name="accountPixKey"
                          [(ngModel)]="bankAccount.accountPixKey"
                          class="form-control"
                        >
                          <option value="1">CPF</option>
                          <option value="2">Celular</option>
                          <option value="3">Email</option>
                          <option value="4">Aleatória</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mt-4">
                    <div class="col-6 text-center">
                      <button
                        type="button"
                        class="btn btn-lg gradient-mint btn-block"
                        (click)="inserirOuAtualizarConta()"
                        [disabled]="
                          !bankAccount ||
                          !bankAccount.accountHolder ||
                          !bankAccount.userCpf ||
                          !bankAccount.bank ||
                          !bankAccount.agency ||
                          !bankAccount.account ||
                          !bankAccount.accountType
                        "
                      >
                        {{
                          bankAccount && bankAccount.id
                            ? "Atualizar"
                            : "Cadastrar"
                        }}
                      </button>
                    </div>
                  </div>
                </form>
              </ng-template>
            </ngb-tab>
            <ngb-tab title="Regras do Programa">
              <ng-template ngbTabContent>
                <div class="row mt-2">
                  <div class="col-12 col-lg-10">
                    <p>
                      O Indique e Ganhe é o programa de indicações do Personal
                      Digital
                    </p>

                    <p>
                      Funcionará da seguinte forma: A cada indicação que você
                      fizer e o Personal fechar um plano conosco, você irá
                      <b>ganhar uma super bonificação em dinheiro!</b>
                    </p>

                    <p class="text-bold-600">
                      Segue abaixo as regras do Indique e Ganhe:
                    </p>

                    <ul>
                      <li>
                        <b>Plano anual</b>: cada personal que você indicar e
                        fechar um <b>plano anual</b>, você ganhará
                        <b>R$ 100,00</b>
                      </li>

                      <li>
                        <b>Plano semestral</b>: cada personal que você indicar e
                        fechar um <b>plano semestral</b>, você ganhará
                        <b>R$ 60,00</b>
                      </li>

                      <li>
                        <b>Plano Mensal</b>: cada personal que você indicar e
                        fechar um <b>plano mensal</b>, você ganhará
                        <b>R$ 40,00</b>
                      </li>
                    </ul>

                    <p style="font-size: 1.2em; font-weight: 600">
                      Lembrando que não há limite de indicações, quanto mais
                      assinaturas forem realizadas com a sua indicação, mais
                      grana você ganhará
                    </p>

                    <p>
                      As suas indicações serão validadas 30 dias após a
                      efetivação do pagamento por parte do cliente que você
                      indicou e a comissão será paga no dia 10 do mês
                      subsequente a validação. Abaixo temos um exemplo:
                    </p>
                    <ul>
                      <li>
                        Uma indicação realizou o pagamento da assinatura no dia
                        <b>13/06/2023</b>
                      </li>
                      <li>
                        A Validação da comissão ocorrerá em <b>13/07/2023</b>
                      </li>
                      <li>
                        Você receberá o pagamento pela indicação no dia
                        <b>10/08/2023</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
  <!--Pills Ends-->
</div>

<div [ngClass]="showLinks ? 'mb-10' : 'mb-5'"></div>

<div
  class="position-fixed floating-menu bg-white d-flex flex-column justify-content-center align-items-center"
>
  <button
    type="button"
    (click)="toggleLinks()"
    class="btn btn-success btn-md-lg text-bold-600 px-3 px-md-4 py-1 py-md-2 invite__button"
    [ngClass]="showLinks && 'mb-4'"
  >
    Convide para ganhar até R$ 100,00
  </button>
  <div class="w-100 d-flex flex-column" *ngIf="showLinks">
    <div class="w-100 position-relative mb-4">
      <div class="w-100 bg-light h-1px"></div>
      <span
        class="position-absolute font-small-2 text-bold-400 px-4 bg-white m-0 text-secondary floating-menu__text"
        >Compartilhar via</span
      >
    </div>
    <div
      class="w-100 d-flex align-items-center justify-content-around justify-content-md-center"
    >
      <button
        type="button"
        (click)="myCodeLink && copyMyLink(myCodeLink)"
        class="btn btn-sm d-flex flex-column align-items-center justify-content-center mx-0 mx-md-4"
      >
        <img
          src="../../../../assets/img/copy_url_icon.svg"
          alt=""
          class="mb-1 img-fluid"
        />
        <span class="mb-0 text-secondary font-small-2">Copiar Link</span>
      </button>
      <a
        [href]="
          'https://wa.me/?text=Com%20o%20Personal%20Digital,%20voc%C3%AA%20cria%20treinos%20personalizados%20em%20poucos%20minutos%20e%20ainda%20pode%20ser%20recompensado%20por%20cada%20novo%20assinante.%0A%0AFa%C3%A7a%20seu%20cadastro%20agora%20mesmo:%20' +
          myCodeLink
        "
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-sm d-flex flex-column align-items-center justify-content-center mx-0 mx-md-4"
      >
        <img
          src="../../../../assets/img/whatsapp_icon.svg"
          alt=""
          class="mb-1 img-fluid"
        />
        <span class="mb-0 text-secondary font-small-2">WhatsApp</span>
      </a>
      <!-- <a
        href="mailto:?subject=Convite%20para%20Criar%20sua%20Conta"
        class="btn btn-sm d-flex flex-column align-items-center justify-content-center mx-0 mx-md-4"
      >
        <img
          src="../../../../assets/img/message_icon.svg"
          alt=""
          class="mb-1 img-fluid"
        />
        <span class="mb-0 text-secondary font-small-2">Email</span>
      </a> -->
      <!-- <button
        type="button"
        class="btn btn-sm d-flex flex-column align-items-center justify-content-center mx-0 mx-md-4"
      >
        <img
          src="../../../../assets/img/instagram_icon.svg"
          alt=""
          class="mb-1 img-fluid"
        />
        <span class="mb-0 text-secondary font-small-2">Instagram</span>
      </button> -->
    </div>
  </div>
</div>

<ng-template #contentVideo>
  <div class="embed-container">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
