  <div class="row mb-2">
    <div class="col">
      <h2 class="text-bold-300">Produtos / Serviços</h2>
    </div>
  </div>
  <div class="row text-left mb-2">
    <div class="col-sm-12">
      <h5 class="text-bold-300">Está com dúvida em como cadastrar seus produtos, veja o video abaixo</h5>
      <button class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton" (click)="OpenModalXl(contentVideo)"><i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial</button>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col" >
      <div class=" float-right">
        <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openModal(modalNewPlan)">Criar Produto/Serviço</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        
        <div class="card-content">
          <div class="card-body">
            <ngb-tabset type="pills">
              <ngb-tab title="Ativos">
  
                <ng-template ngbTabContent>
                  <div class="row mb-2 mt-2" *ngIf="planosAtivos && planosAtivos.length > 0">
                    <div class="col">
                      <div class="card-content">
  
                        <div class="card-body p-0">
  
  
  
                          <div class="table text-center m-0">
                            <table class="table-striped table-responsive-md" style="width: 100%;">
                              <thead>
                              <tr>
                                <th class="p-2">Nome</th>
                                <th class="p-2">Tipo de Cobrança</th>
                                <th class="p-2">Preço</th>
                                <th class="p-2">Status</th>
                                <th class="p-2">Ações</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let plano of planosAtivos| slice: (pageP-1) * pageSizeP : (pageP-1) * pageSizeP + pageSizeP">
                                <td class="p-2">{{plano.name}}</td>
                                <td class="p-2">{{plano.plan_type == 0 ? 'Compra única' : 'Recorrente'}}</td>
                                <td class="p-2">{{plano.plan_type == 0 && plano.value_cents ? ((plano.value_cents/100) | currency : 'R$') : plano.Plans && plano.Plans[0] ? ((plano.Plans[0].value_cents/100) | currency : 'R$') : '---' }}</td>
                                <td class="p-2">{{plano.status ? 'Ativo' : 'Inativo'}}</td>
                                <td>
                                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                                    <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                                      <i class="ft-more-vertical"></i>
                                    </button>
                                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                      <a class="dropdown-item" (click)="switchProductStatus(plano)">{{plano.status ? 'Desativar Produto' : 'Ativar Produto'}}</a>
                                      <a class="dropdown-item" (click)="editProduct(plano, modalNewPlan)">Editar Produto</a>
                                      <a class="dropdown-item" (click)="compartilharProduto(plano, CheckoutInfos)">Gerar Link de Venda</a>
                                      <a class="dropdown-item" (click)="viewThankyouPage(plano)">Ver Página de Obrigado</a>
                                   </div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
  
                        </div>
                        <div class="form-row" style="margin: 5px">
                          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                            <ngb-pagination style="margin-top: 10px" *ngIf="planosAtivos && planosAtivos.length>0"
                                            [(page)]="pageP"
                                            [pageSize]="pageSizeP"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                            [collectionSize]="planosAtivos.length"></ngb-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="row mb-2 mt-2" *ngIf="planosAtivos.length == 0">
                    <div class="col">
                      <div class="card-content">
  
                        <div class="card-body p-0">
                          <p>Nenhum Plano Ativo!</p>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
                </ng-template>
              </ngb-tab>
  
  
  
              <ngb-tab title="Inativos">
  
                <ng-template ngbTabContent>
                  <div class="row mb-2 mt-2" *ngIf="planosInativos &&  planosInativos.length > 0">
                    <div class="col">
                      <div class="card-content">
  
                        <div class="card-body p-0">
  
  
                          <div class="table text-center m-0">
                            <table class="table-striped table-responsive-md" style="width: 100%;">
                              <thead>
                              <tr>
                                <th class="p-2">Nome</th>
                                <th class="p-2">Tipo de Cobrança</th>
                                <th class="p-2">Preço</th>
                                <th class="p-2">Status</th>
                                <th class="p-2">Ações</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let plano of planosInativos| slice: (pageP-1) * pageSizeP : (pageP-1) * pageSizeP + pageSizeP">
                                <td class="p-2">{{plano.name}}</td>
                                <td class="p-2">{{plano.plan_type == 0 ? 'Compra única' : 'Recorrente'}}</td>
                                <td class="p-2">{{plano.plan_type == 0 && plano.value_cents ? ((plano.value_cents/100) | currency : 'R$') : plano.Plans && plano.Plans[0] ? ((plano.Plans[0].value_cents/100) | currency : 'R$') : '---' }}</td>
                                <td class="p-2">{{plano.status ? 'Ativo' : 'Inativo'}}</td>
                                <td>
                                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                                    <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                                      <i class="ft-more-vertical"></i>
                                    </button>
                                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                      <a class="dropdown-item" (click)="switchProductStatus(plano)">{{plano.status ? 'Desativar Produto' : 'Ativar Produto'}}</a>
                                      <a class="dropdown-item" (click)="editProduct(plano, modalNewPlan)">Editar Produto</a>
                                      <a class="dropdown-item" (click)="compartilharProduto(plano, CheckoutInfos)">Gerar Link de Venda</a>
                                      <a class="dropdown-item" (click)="viewThankyouPage(plano)">Ver Página de Obrigado</a>
                                   </div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
  
                        </div>
                        <div class="form-row" style="margin: 5px">
                          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                            <ngb-pagination style="margin-top: 10px" *ngIf="planosInativos && planosInativos.length>0"
                                            [(page)]="pageP"
                                            [pageSize]="pageSizeP"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                            [collectionSize]="planosInativos.length"></ngb-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="row mb-2 mt-2" *ngIf="planosInativos.length == 0">
                    <div class="col">
                      <div class="card-content">
  
                        <div class="card-body p-0">
                          <p>Nenhum Plano Inativo!</p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
  
  
                </ng-template>
              </ngb-tab>
  
  
  
            </ngb-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #modalNewInvoice let-modal>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Nova Fatura</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
            <aw-wizard-step [stepTitle]="'Dados Cliente'" [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
              <form #firstForm="ngForm" class="editForm" novalidate>
              <br/>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="email">Email</label>
                      <input type="text" id="email" class="form-control" [(ngModel)]="newInvoice.payer.email" name="email"  placeholder="Email" #inputemail="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputemail.errors?.required">Informe o Email</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Nome</label>
                      <input type="text" id="nome" class="form-control" [(ngModel)]="newInvoice.payer.name" name="nome"  placeholder="Nome" #inputname="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputname.errors?.required">Informe o Nome</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf_cnpj">CPF/CNPJ</label>
                      <input type="text" id="cpf_cnpj" class="form-control" [(ngModel)]="newInvoice.payer.cpf_cnpj" name="cpf_cnpj"  mask="000.000.000-00||00.000.000/0000-00" placeholder="CPF/CNPJ" #inputcpfcnpj="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputcpfcnpj.errors?.required">Informe o CPF/CNPJ</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cep">CEP</label>
                      <input type="text" id="cep" class="form-control" [(ngModel)]="newInvoice.payer.zip_code" name="cep" mask="00000-000" placeholder="Cep" (change)="consultaCep()" #inputCep="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputCep.errors?.required">Informe o Cep</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="street">Rua/Avenida</label>
                      <input type="text" id="street" class="form-control" [(ngModel)]="newInvoice.payer.street" name="street" #inputEndereco="ngModel"  placeholder="Rua/Avenida" required>
                      <small class="form-text text-muted danger" *ngIf="inputEndereco.errors?.required">Informe a rua</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="number">Número</label>
                      <input type="text" id="number" class="form-control" [(ngModel)]="newInvoice.payer.number" name="number" #inputnumber="ngModel"  placeholder="Número" required>
                      <small class="form-text text-muted danger" *ngIf="inputnumber.errors?.required">Informe o número</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-8 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="city">Cidade</label>
                      <input type="text" id="city" class="form-control" [(ngModel)]="newInvoice.payer.city" name="city" #inputcidade="ngModel" placeholder="Cidade" required>
                      <small class="form-text text-muted danger" *ngIf="inputcidade.errors?.required">Informe a cidade</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="state">Estado</label>
                      <ng-select [items]="estados"  name="state"  placeholder="Estado" id="state" [(ngModel)]="newInvoice.payer.state" #inputestado="ngModel"  required ></ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputestado.errors?.required">Informe o estado</small>
                    </div>
                  </div>
                </div>
              </div>
                <div class="form-row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-lg btn-danger mr-2"(click)="modal.close('Close click')">Cancelar</button>
                    <button type="button" class="btn btn-lg gradient-mint" [disabled]="!firstForm.valid" awNextStep>Próximo Passo</button>
                  </div>
                </div>
              </form>
            </aw-wizard-step>
            <aw-wizard-step [stepTitle]="'Dados Fatura'" [navigationSymbol]="{ symbol: '&#xf155;', fontFamily: 'FontAwesome' }">
              <form #contaForm="ngForm" class="editForm" novalidate>
                <br/>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="due_date">Data de Vencimento</label>
                        <input type="text" id="due_date" class="form-control" [(ngModel)]="newInvoice.due_date" name="due_date" mask="00/00/0000" placeholder="Data de Vencimento" #inputduedate="ngModel" required>
                        <small class="form-text text-muted danger" *ngIf="inputduedate.errors?.required">Informe a Data de Vencimento</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group scrollable-box-1">
                      <div class="controls">
                        <label for="tipoPagamentoEscolhido">Tipo de Pagamento</label>
                        <ng-select [appendTo]="'.scrollable-box-1'" [items]="tiposPagamento"  name="tipoPagamentoEscolhido" bindLabel="label"  placeholder="Tipo de Pagamento" id="tipoPagamentoEscolhido" [(ngModel)]="tipoPagamentoEscolhido" #inputtipopagamento="ngModel"  required ></ng-select>
                        <small class="form-text text-muted danger" *ngIf="inputtipopagamento.errors?.required">Informe o Tipo de Pagamento</small>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row mb-2">
                  <div class="col-sm" style="align-self: center;">
                    <h5 style="text-align: center;">{{idItemInEdit!=null ? 'Atualização Item Fatura' : 'Cadastro Item Fatura'}}</h5>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <div class="form-group">
                      <label for="idescription">Descrição</label>
                      <input type="text" autocomplete="off" id="idescription" class="form-control" name="idescription" [(ngModel)]="newInvoiceItem.description" #inputitemdescription="ngModel">
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="iquantity">Quantidade</label>
                      <input type="text" autocomplete="off" id="iquantity" class="form-control" mask="0*" name="iquantity" [(ngModel)]="newInvoiceItem.quantity" #inputitemquantity="ngModel">
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="iprice">Preço</label>
                      <input type="text" autocomplete="off" id="iprice" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." [allowNegativeNumbers]="true" name="iprice" [(ngModel)]="newInvoiceItem.price_cents" #inputitemprice="ngModel">
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div *ngIf="idItemInEdit!=null" class="col-sm" style="align-self: center;">
                    <button (click)="cancelItemInvoice()" type="button" class="btn btn-block btn-outline-danger btn-icon" style="margin-top: 7px;"><i class="fa fa-close"></i></button>
                  </div>
                  <div *ngIf="idItemInEdit!=null" class="col-sm" style="align-self: center;">
                  </div>
                  <div class="col-sm" style="align-self: center;">
                    <button [disabled]="!newInvoiceItem.description|| !newInvoiceItem.quantity || newInvoiceItem.quantity<=0 || !newInvoiceItem.price_cents" (click)="submitItemInvoice(false)" type="button" class="btn btn-block gradient-mint btn-icon" style="margin-top: 7px;"><i class="fa fa-save"></i></button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead class="thead-dark">
                        <tr>
                          <th class="text-center p-2">Descrição</th>
                          <th class="text-center p-2">Quantidade</th>
                          <th class="text-center p-2">Preço</th>
                          <th class="text-center p-2">#</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of newInvoice.items; let i = index">
                            <td class="text-center p-2">{{item.description ? item.description : '--'}}</td>
                            <td class="text-center p-2">{{item.quantity ? item.quantity : '--'}}</td>
                            <td class="text-center p-2">{{item.price_cents ? ((item.price_cents/100) | currency : 'R$')  : '--'}}</td>
                            <td class="text-center p-2">
                              <div class="btn-group btn-group-sm pl-0 pr-0" role="group">
                                <button type="button" (click)="editItemInvoice(item, i)"  class="btn btm-sm gradient-mint btn-icon round" ><i class="fa fa-edit"></i></button>
                                <button type="button" (click)="deleteItemInvoice(i, item, false)"  class="btn btm-sm btn-danger btn-icon round"  ><i class="fa fa-trash"></i></button>
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="!newInvoice || !newInvoice.items || newInvoice.items.length==0">
                            <td colspan="4"><p class="text-center">Nenhum item adicionado</p></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="4" class="text-right p-2">{{getTotalInvoice(false)}}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-lg btn-secondary mr-2" awPreviousStep>Voltar</button>
                    <button type="button" class="btn btn-lg gradient-mint" (click)="submitInvoice(modal)" [disabled]="!contaForm.valid || !tipoPagamentoEscolhido || newInvoice.items.length==0 || !verifyDate(false)" awNextStep>Cadastrar</button>
                  </div>
                </div>
              </form>
            </aw-wizard-step>
          </aw-wizard>
        </div>
      </div>
    </div>
  </ng-template>

   <ng-template #modalDuplicateInvoice let-modal>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Segunda Via</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <form #contaForm="ngForm" class="editForm" novalidate>
            <br/>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="due_date">Data de Vencimento</label>
                    <input type="text" id="due_date" class="form-control" [(ngModel)]="invToDuplicate.due_date" name="due_date" mask="00/00/0000" placeholder="Data de Vencimento" #inputduedate="ngModel" required>
                    <small class="form-text text-muted danger" *ngIf="inputduedate.errors?.required">Informe a Data de Vencimento</small>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="row mb-2">
              <div class="col-sm" style="align-self: center;">
                <h5 style="text-align: center;">{{idItemInEdit!=null ? 'Atualização Item Fatura' : 'Cadastro Item Fatura'}}</h5>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <div class="form-group">
                  <label for="idescription">Descrição</label>
                  <input type="text" autocomplete="off" id="idescription" class="form-control" name="idescription" [(ngModel)]="newInvoiceItem.description" #inputitemdescription="ngModel">
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm">
                <div class="form-group">
                  <label for="iquantity">Quantidade</label>
                  <input type="text" autocomplete="off" id="iquantity" class="form-control" mask="0*" name="iquantity" [(ngModel)]="newInvoiceItem.quantity" #inputitemquantity="ngModel">
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="iprice">Preço</label>
                  <input type="text" autocomplete="off" id="iprice" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." [allowNegativeNumbers]="true" name="iprice" [(ngModel)]="newInvoiceItem.price_cents" #inputitemprice="ngModel">
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div *ngIf="idItemInEdit!=null" class="col-sm" style="align-self: center;">
                <button (click)="cancelItemInvoice()" type="button" class="btn btn-block btn-outline-danger btn-icon" style="margin-top: 7px;"><i class="fa fa-close"></i></button>
              </div>
              <div *ngIf="idItemInEdit!=null" class="col-sm" style="align-self: center;">
              </div>
              <div class="col-sm" style="align-self: center;">
                <button [disabled]="!newInvoiceItem.description|| !newInvoiceItem.quantity || newInvoiceItem.quantity<=0 || !newInvoiceItem.price_cents" (click)="submitItemInvoice(true)" type="button" class="btn btn-block gradient-mint btn-icon" style="margin-top: 7px;"><i class="fa fa-save"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                    <tr>
                      <th class="text-center p-2">Descrição</th>
                      <th class="text-center p-2">Quantidade</th>
                      <th class="text-center p-2">Preço</th>
                      <th class="text-center p-2">#</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of invToDuplicate.items; let i = index">
                        <td class="text-center p-2">{{item.description ? item.description : '--'}}</td>
                        <td class="text-center p-2">{{item.quantity ? item.quantity : '--'}}</td>
                        <td class="text-center p-2">{{item.price_cents ? ((item.price_cents/100) | currency : 'R$')  : '--'}}</td>
                        <td class="text-center p-2">
                          <div class="btn-group btn-group-sm pl-0 pr-0" role="group">
                            <button type="button" *ngIf="!item.id" (click)="editItemInvoice(item, i)"  class="btn btm-sm gradient-mint btn-icon round" ><i class="fa fa-edit"></i></button>
                            <button type="button" (click)="deleteItemInvoice(i, item, true)"  class="btn btm-sm btn-danger btn-icon round"  ><i class="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!invToDuplicate || !invToDuplicate.items || invToDuplicate.items.length==0">
                        <td colspan="4"><p class="text-center">Nenhum item adicionado</p></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="4" class="text-right p-2">{{getTotalInvoice(true)}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-secondary mr-2" (click)="activeModal.close('Close click')">Voltar</button>
                <button type="button" class="btn btn-lg gradient-mint" (click)="submitDuplicateInvoice(modal)" [disabled]="!contaForm.valid || invToDuplicate.items.length==0 || !verifyDate(true)">Gerar Segunda Via</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalNewPlan let-modal>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{newProduct.id ? 'Edite seu produto' : 'Cadastre seu produto'}}</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
            <aw-wizard-step [stepTitle]="'Produto'"  [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
              <form #productData="ngForm" class="editForm" novalidate>
              <br/>
              <div class="row text-left mb-4">
                <div class="col-sm-12">
                <button class="btn btn-warning border-right-warning mb-2 mb-md-0" (click)="helperInfos(1, inputsHelper)" placement="top"><i class="fa fa-question-circle"></i> Veja como preencher as informações</button>
                  </div>
                </div>
              
                
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Insira o nome do seu produto <i class="fa fa-info-circle" ngbTooltip="Ex: Consultoria Online - Plano Trimestral"></i></label>

                      <input type="text" id="nome" class="form-control" [(ngModel)]="newProduct.name" name="nome"  placeholder="Ex: Consultoria Online - Plano Trimestral" #inputnameplan="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputnameplan.errors?.required">Informe o Nome</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Descrição do Produto <i class="fa fa-info-circle" ngbTooltip="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc"></i></label>
                      <textarea id="desc" rows="2" class="form-control" name="desc"  #planDescription maxlength="255"  [(ngModel)]="newProduct.description" #inputDesc="ngModel" placeholder="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc" required></textarea>                  
                      <div class="form-row__description">
                        {{ 255 - planDescription.value.length }} caracteres restantes.
                      </div>
                      <small class="form-text text-muted danger" *ngIf="inputDesc.errors?.required">Informe a Descrição</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <!--<div class="col-3">
                  <div class="form-group">
                    <div class="controls">
                      <label for="monthly">Mensal</label><br>
                      <ui-switch checked class="switchery" placement="right" readonly="true"  ngbTooltip="Ative a recorrência mensal." name="status" [(ngModel)]="productsPlans.monthly.status"></ui-switch>
                    </div>
                  </div>
                </div> -->
                
              </div>
              <div class="form-row">
                <!--<div class="col-3">
                  <div class="form-group">
                    <div class="controls">
                      <label for="monthly">Mensal</label><br>
                      <ui-switch checked class="switchery" placement="right" readonly="true"  ngbTooltip="Ative a recorrência mensal." name="status" [(ngModel)]="productsPlans.monthly.status"></ui-switch>
                    </div>
                  </div>
                </div> -->
                
              </div>            
            <!-- <div class="form-row" *ngIf="tipoPlanoEscolhido && tipoPlanoEscolhido.id==0">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="value_cents">Preço</label>
                      <input type="text" id="value_cents" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." [(ngModel)]="newProduct.value_cents" name="value_cents"  placeholder="Preço" #inputpriceplan="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputpriceplan.errors?.required">Informe o Preço</small>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="form-row mb-4">
                <div class="col-12 col-lg-6 text-center mb-2">
                  <button type="button" class="btn btn-lg gradient-mint"(click)="selecionarImagemProduto()" style="width: 100%" placement="top">Imagem do Produto</button>
                  <img class="mt-2" *ngIf="product_image_url" [src]="product_image_url"
                      style="margin-left: auto; margin-right: auto; max-width: 350px;">
                </div>
                <div class="col-12 col-lg-6 text-center mb-2">
                  <button type="button" class="btn btn-lg gradient-mint"(click)="selecionarImagemLogoObrigado()" style="width: 100%" placement="top">Sua Logo</button>
                  <img class="mt-2" *ngIf="delivery_logo_url" [src]="delivery_logo_url"
                  style="margin-left: auto; margin-right: auto; max-width: 350px;">
                </div>
                
              </div>
              <!--<div class="form-row mb-4" *ngIf="product_image_url">
                <div class="col text-center">
                  <img [src]="product_image_url"
                      style="margin-left: auto; margin-right: auto; max-width: 350px;">
                </div>
              </div> -->
              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-danger mr-2" (click)="modal.close('Close click')">Cancelar</button>
                  <button type="button" class="btn btn-lg btn-success" [disabled]="!productData.valid" awNextStep>Próximo Passo</button>              
                </div>
              </div>
              </form>
            </aw-wizard-step>
            <aw-wizard-step [stepTitle]="'Pagamento'"  [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
              <form #productData="ngForm" class="editForm" novalidate>
              <br/>
              <div class="row text-left mb-4">
                <div class="col-sm-12">
                <button class="btn btn-warning border-right-warning mb-2 mb-md-0" (click)="helperInfos(1, inputsHelper)" placement="top"><i class="fa fa-question-circle"></i> Veja como preencher as informações</button>
                  </div>
                </div>
              
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="nome">selecione o tipo de cobrança</label>
                    <ng-select [items]="tiposPlanos"  bindLabel="label" name="tipoCobranca"  placeholder="Selecionar Cobrança" id="basic-form-model" [(ngModel)]="newProduct.plan_type" [disabled]="newProduct.id" required="true"></ng-select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group" *ngIf="newProduct && newProduct.plan_type && newProduct.plan_type.id==1">
                    <div class="controls">
                      <label for="monthly_value">Valor Mensal a Cobrar</label>
                      <input type="text" id="value" class="form-control" prefix="R$ " mask="separator.2" thousandSeparator="." [(ngModel)]="productsPlans.monthly.value_cents" name="monthly_value"  placeholder="Valor Total do Plano" #inputpriceplan="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputpriceplan.errors?.required">Informe o Preço</small>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="newProduct && newProduct.plan_type && newProduct.plan_type.id==0">
                    <div class="controls">
                      <label for="monthly_value">Valor Total a Cobrar</label>
                      <input type="text" id="value" class="form-control" prefix="R$ " mask="separator.2" thousandSeparator="." [(ngModel)]="newProduct.value_cents" name="monthly_value"  placeholder="Valor Total do Plano" #inputpriceplan="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputpriceplan.errors?.required">Informe o Preço</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row" *ngIf="newProduct && newProduct.plan_type && newProduct.plan_type.id==1">
                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="monthly_value">Cobrar durante quantos meses?</label><br>
                      <small>Digite 0 (zero) se não quer que a cobrança tenha término</small>
                      <input type="number" id="value" class="form-control" [(ngModel)]="productsPlans.monthly.max_cycles" name="max_cycles"  placeholder="Cobrar durante quantos meses?" #inputmaxcycles="ngModel" [disabled]="newProduct.id" required>
                      <small class="form-text text-muted danger" *ngIf="inputmaxcycles.errors?.required">Informe a quantidade de meses.</small>
                    </div>
                  </div>
                </div>
              </div>            
            <!-- <div class="form-row" *ngIf="tipoPlanoEscolhido && tipoPlanoEscolhido.id==0">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="value_cents">Preço</label>
                      <input type="text" id="value_cents" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." [(ngModel)]="newProduct.value_cents" name="value_cents"  placeholder="Preço" #inputpriceplan="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputpriceplan.errors?.required">Informe o Preço</small>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="form-row">
                <div class="col">
                  <div class="form-group scrollable-box-2">
                    <div class="controls">
                      <label for="tipoPagamentoEscolhido">Tipo de Pagamento</label>
                      <ng-select [appendTo]="'.scrollable-box-2'" [notFoundText]="'Nenhuma forma de Pagamento encontrada'" [items]="tiposPagamento"  bindLabel="label" name="model"  placeholder="Selecionar Formas de Pagamento" id="tiposPagamento" [(ngModel)]="tipoPagamentoEscolhido" (change)="checkFormasPagamento()" multiple="true" #inputtipopagamento="ngModel" required="true">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div *ngIf=" tipoPagamentoEscolhido.length == 1" class="ng-values-list">
                            <div class="ng-value" *ngFor="let item of items | slice:0:1">
                              <span class="ng-value-label">{{item.label}}</span>
                              <span class="ng-value-icon right" (click)="clear(item)">×</span>
                            </div>
                          </div>
                          <div *ngIf="tipoPagamentoEscolhido.length > 1" class="ng-summary-list">
                            <div class="ng-value">
                              <span class="ng-value-label">{{ tipoPagamentoEscolhido.length }} Selecionados</span>
                            </div>
                          </div>
                        </ng-template> 
                        </ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputtipopagamento.errors?.required">Informe o Tipo de Pagamento</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12 col-12" *ngIf="newProduct && newProduct.plan_type && newProduct.plan_type.id==1">
                  <div class="form-group">
                    <div class="controls">
                      <label for="ttt" >Atrelar perfil do Aluno?</label>
                      <ui-switch class="switchery switchery-warning"  id="ttt"   [checked]="isAtrelarAluno" (change)="changeAtrelarAluno($event)"></ui-switch>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="form-row" *ngIf="newProduct && newProduct.plan_type && newProduct.plan_type.id==0">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="nome">Quantidade máxima de parcelas</label>
                    <ng-select [items]="parcelado"  bindLabel="label" name="parcelamento"  placeholder="Selecionar Parcelas" id="basic-form-model" [(ngModel)]="parcelaSelecionada" (change)="getTabelaParcelamento()"></ng-select>
                  </div>
                </div>
              </div> -->

              <!-- <div class="form-row" *ngIf="parcelaSelecionada && parcelaSelecionada.parcela && tipoPagamentoEscolhido && newProduct && newProduct.plan_type && newProduct.plan_type.id==0">
                <div class="form-row" style="margin: 5px">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="thead-dark">
                      <tr>
                        <th class="text-center p-2">Parcela</th>
                        <th class="text-center p-2">Valor Parc</th>
                        <th class="text-center p-2">Liquido</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let p of parcelado">
                        <td class="text-center p-2" *ngIf="p.parcela <= parcelaSelecionada.parcela">{{parcelado.indexOf(p)+1+'x'}}</td>
                        <td class="text-center p-2" *ngIf="p.parcela <= parcelaSelecionada.parcela">{{formatPreco(p.mensalidadeSemTaxa)}}</td>
                        <td class="text-center p-2" *ngIf="p.parcela <= parcelaSelecionada.parcela">{{formatPreco(p.valorLiquido)}}</td>
                      </tr>
                      </tbody>
                    </table>
                </div>
                </div>
              </div> -->

              <!--<div class="form-row mb-4" *ngIf="product_image_url">
                <div class="col text-center">
                  <img [src]="product_image_url"
                      style="margin-left: auto; margin-right: auto; max-width: 350px;">
                </div>
              </div> -->
              <div class="form-row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-lg btn-secondary mr-2" awPreviousStep>Voltar</button>
                  <button type="button" class="btn btn-lg btn-success" [disabled]="!productData.valid" awNextStep>Próximo Passo</button>              
                </div>
              </div>
              </form>
            </aw-wizard-step>
            <aw-wizard-step [stepTitle]="'Entrega'" [navigationSymbol]="{ symbol: '&#xf15b;', fontFamily: 'FontAwesome' }">
              <form #deliveryData="ngForm" class="editForm" novalidate>
                <div class="row text-left mb-4">
                  <div class="col-sm-12">
                  <button class="btn btn-warning border-right-warning mb-2 mb-md-0" (click)="helperInfos(2, inputsHelper)" placement="top"><i class="fa fa-question-circle"></i> Veja como preencher as informações</button>
                    </div>
                  </div>
                <p class="text-center">Insira as informações necessárias que irão aparecer para o cliente, logo após a compra do seu produto.</p>
                <br/>
                <div class="form-group mb-2">
                  <p class="text-center text-bold-600">CONTEÚDO DA PÁGINA DE OBRIGADO <i class="fa fa-info-circle" ngbTooltip="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc"></i></p>
                  <small class="text-center">Insira uma imagem de "compra concluída" ou um vídeo de agradecimento com os próximos passos do seu cliente</small>
                </div>
                <div class="form-row mb-2">
                  <div class="col text-center">
                    <div class="d-md-none">
                      <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                        <button type="button" class="btn btn-lg gradient-mint"(click)="toggleVideoOrGif(true, true)" style="width: 33%" placement="top" ngbTooltip="Você pode inserir um link de um vídeo do Youtube para a página de compra finalizada." ><i class="fa fa-youtube"></i></button>
                        <button type="button" style="width: 33%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(true, false)" placement="top" ngbTooltip="Você pode fazer upload do vídeo da página de obrigado." ><i class="fa fa-upload"></i></button>
                        <button type="button" style="width: 33%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(false, false)" placement="top" ngbTooltip="Você pode fazer upload de uma imagem para a página de obrigado." ><i class="fa fa-image"></i></button>
                      </div>
                    </div>
                    <div class="d-none d-md-block">
                        <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                          <button type="button" class="btn btn-lg gradient-mint"(click)="toggleVideoOrGif(true, true)" style="width: 33%" placement="top" ngbTooltip="Você pode inserir um link de um vídeo do Youtube para a página de compra finalizada." >Link do YouTube</button>
                          <button type="button" style="width: 33%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(true, false)" placement="top" ngbTooltip="Você pode fazer upload do vídeo da página de obrigado." >Upload Video</button>
                          <button type="button" style="width: 33%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(false, false)" placement="top" ngbTooltip="Você pode fazer upload de uma imagem para a página de obrigado." >Upload Imagem</button>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-2">
                  <p class="text-center">Seu vídeo não está em formato MP4? <a href="https://convert-video-online.com/pt" target="_blank" rel="noopener noreferrer">Clique aqui para converter.</a></p>
                </div>
                <div class="form-row mb-2" *ngIf="url_video">
                  <div class="col text-center">
                    <ng-template [ngIf]="url_video.startsWith('https://www.youtube.com/') || url_video.startsWith('http://www.youtube.com/')">
                      <div id="video" #videoYtb class="auto-resizable-iframe">
                        <div>
                          <iframe frameborder="0" allowfullscreen="" [src]="safeURL"></iframe>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template [ngIf]="!url_video.startsWith('https://www.youtube.com/') && !url_video.startsWith('http://www.youtube.com/')">
                      <video #video id="video" controls style="margin-left: auto; margin-right: auto; max-width: 500px">
                        <source [src]="url_video" type="video/mp4">
                      </video>
                    </ng-template>
                  </div>
                </div>
                <div class="form-row mb-4" *ngIf="delivery_image_url">
                  <div class="col text-center">
                    <img [src]="delivery_image_url"
                        style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  </div>
                </div>
                <hr>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="nome">Informações da Página de Entrega do Produto <i class="fa fa-info-circle" ngbTooltip="Mensagem que seu cliente irá receber imediatamente após a compra do seu produto."></i></label>
                        
                        <textarea id="desc" rows="2" class="form-control" name="desc"  #delivery_instructions maxlength="255"  [(ngModel)]="newProduct.delivery_instructions" #instructions="ngModel" placeholder="Mensagem que seu cliente irá receber imediatamente após a compra do seu produto." required></textarea>                  
                        <div class="form-row__description">
                          {{ 255 - delivery_instructions.value.length }} caracteres restantes.
                        </div>
                        <small class="form-text text-muted danger" *ngIf="instructions.errors?.required">Informe as Informações da Página de Entrega do Produto</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="nome">Link de Acesso ao Produto <i class="fa fa-info-circle" ngbTooltip="Insira aqui o link de acesso ao seu produto (link de convite do app, desafio, ebook, etc)"></i></label>
                        <input type="url" id="delivery_link" class="form-control" name="delivery_link"  [(ngModel)]="newProduct.delivery_link" #inputDesc="ngModel" placeholder="Insira aqui o link de acesso ao seu produto (link de convite do app, desafio, ebook, etc)">       
                        </div>
                    </div>
                  </div>
                </div>
              <!-- <div class="form-row mb-4">
                  <div class="col text-center">
                    <button type="button" class="btn btn-lg gradient-mint"(click)="selecionarImagemLogoObrigado()" style="width: 50%" placement="top">Logo da Página de Obrigado</button>
                    <img [src]="delivery_logo_url"
                    style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  </div>
                </div>
                <div class="form-row mb-4" *ngIf="delivery_logo_url">
                  <div class="col text-center">
                    <img [src]="delivery_logo_url"
                        style="margin-left: auto; margin-right: auto; max-width: 350px;">
                  </div>
                </div> -->
                          
                  
              
                <div class="form-row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-lg btn-light mr-2" awPreviousStep>Voltar</button>
                    <button type="button" class="btn btn-lg btn-success" (click)="newProduct.id ? submitUpdatePlan(modal) : submitPlan(modal)" [disabled]="!deliveryData.valid" awNextStep>Cadastrar Produto</button>              
                  </div>
                </div>
              </form>
            </aw-wizard-step>
          </aw-wizard>
       </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalNewSubscribe let-modal>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Nova Assinatura</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
            <aw-wizard-step [stepTitle]="'Dados Cliente'" [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
              <form #firstForm="ngForm" class="editForm" novalidate>
              <br/>
              
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="email">Email</label>
                      <input type="text" id="email" class="form-control" [(ngModel)]="newSubscribe.customer.email" name="email"  placeholder="Email" #inputemail="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputemail.errors?.required">Informe o Email</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Nome</label>
                      <input type="text" id="nome" class="form-control" [(ngModel)]="newSubscribe.customer.name" name="nome"  placeholder="Nome" #inputname="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputname.errors?.required">Informe o Nome</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf_cnpj">CPF/CNPJ</label>
                      <input type="text" id="cpf_cnpj" class="form-control" [(ngModel)]="newSubscribe.customer.cpf_cnpj" name="cpf_cnpj"  mask="000.000.000-00||00.000.000/0000-00" placeholder="CPF/CNPJ" #inputcpfcnpj="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputcpfcnpj.errors?.required">Informe o CPF/CNPJ</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cep">CEP</label>
                      <input type="text" id="cep" class="form-control" [(ngModel)]="newSubscribe.customer.zip_code" name="cep" mask="00000-000" placeholder="Cep" (change)="consultaCepAssinatura()" #inputCep="ngModel" required>
                      <small class="form-text text-muted danger" *ngIf="inputCep.errors?.required">Informe o Cep</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="street">Rua/Avenida</label>
                      <input type="text" id="street" class="form-control" [(ngModel)]="newSubscribe.customer.street" name="street" #inputEndereco="ngModel"  placeholder="Rua/Avenida" required>
                      <small class="form-text text-muted danger" *ngIf="inputEndereco.errors?.required">Informe a rua</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="number">Número</label>
                      <input type="text" id="number" class="form-control" [(ngModel)]="newSubscribe.customer.number" name="number" #inputnumber="ngModel"  placeholder="Número" required>
                      <small class="form-text text-muted danger" *ngIf="inputnumber.errors?.required">Informe o número</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="bairro">Bairro</label>
                      <input type="text" id="bairro" class="form-control" [(ngModel)]="newSubscribe.customer.district" name="bairro" #inputdistrict="ngModel"  placeholder="Bairro" required>
                      <small class="form-text text-muted danger" *ngIf="inputdistrict.errors?.required">Informe o bairro</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-8 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="city">Cidade</label>
                      <input type="text" id="city" class="form-control" [(ngModel)]="newSubscribe.customer.city" name="city" #inputcidade="ngModel" placeholder="Cidade" required>
                      <small class="form-text text-muted danger" *ngIf="inputcidade.errors?.required">Informe a cidade</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label for="state">Estado</label>
                      <ng-select [items]="estados"  name="state"  placeholder="Estado" id="state" [(ngModel)]="newSubscribe.customer.state" #inputestado="ngModel"  required ></ng-select>
                      <small class="form-text text-muted danger" *ngIf="inputestado.errors?.required">Informe o estado</small>
                    </div>
                  </div>
                </div>
              </div>
                <div class="form-row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-lg btn-danger mr-2"(click)="modal.close('Close click')">Cancelar</button>
                    <button type="button" class="btn btn-lg gradient-mint" [disabled]="!firstForm.valid" awNextStep>Próximo Passo</button>
                  </div>
                </div>
              </form>
            </aw-wizard-step>
            <aw-wizard-step [stepTitle]="'Dados Assinatura'" [navigationSymbol]="{ symbol: '&#xf155;', fontFamily: 'FontAwesome' }">
              <form #contaForm="ngForm" class="editForm" novalidate>
                <br/>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="due_date">Data de Vencimento Primeira Fatura</label>
                        <input type="text" id="due_date" class="form-control" [(ngModel)]="newSubscribe.expires_at" name="due_date" mask="00/00/0000" placeholder="Data de Vencimento Primeira Fatura" #inputduedate="ngModel" required>
                        <small class="form-text text-muted danger" *ngIf="inputduedate.errors?.required">Informe a Data de Vencimento da Primeira Fatura</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group scrollable-box-3">
                      <div class="controls">
                        <label for="tipoPlanoEscolhido">Plano</label>
                        <ng-select [appendTo]="'.scrollable-box-3'" [items]="planos"  name="tipoPlanoEscolhido" bindLabel="name" bindValue="identifier" placeholder="Plano" id="tipoPlanoEscolhido" [(ngModel)]="newSubscribe.plan_identifier" #inputplanos="ngModel"  required ></ng-select>
                        <small class="form-text text-muted danger" *ngIf="inputplanos.errors?.required">Informe o Plano</small>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-center" *ngIf="newSubscribe.plan_identifier" >{{getPricePlan() | currency : 'R$'}}</p>
                <div class="form-row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-lg btn-secondary mr-2" awPreviousStep>Voltar</button>
                    <button type="button" class="btn btn-lg gradient-mint" (click)="submitSubscribe(modal)" [disabled]="!contaForm.valid || !verifyDateSubscribe()" awNextStep>Cadastrar</button>
                  </div>
                </div>
              </form>
            </aw-wizard-step>
          </aw-wizard>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CheckoutInfos let-modal>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Copiar Link de Checkout</h4>
      </div>
      <div class="card-body">
      <div class="col text-center">
        <input type="text" id="aaaa" class="form-control text-center"  disabled="true" [value]="checkoutLink" name="aaaa" required aria-describedby="basic-addon1">
        <button class="btn btn-warning border-right-warning mb-2 mt-2" (click)="copyCheckoutLink(checkoutLink)">Copiar Link de Checkout</button>
      </div>
    </div>
 
    </div>
 </ng-template>

 <ng-template #inputsHelper let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">{{helperType==1 ? 'Página de Compra' : 'Página de Obrigado'}}</h4>
    </div>
    <div class="card-body">
    <div class="col text-center">
      <img *ngIf="helperImage" [src]="helperImage" style="margin-left: auto; margin-right: auto; width: 100%;">
    </div>
  </div>

  </div>
</ng-template>


<ng-template #contentVideo>
  <!-- <video controls style="width: 100%;">
    <source [src]="'https://files.wecodde.com/file/wecodde/02+-+Como+cadastrar+o+produto_servi%C3%A7o.mp4'" type="video/mp4">
  </video> -->
  <div class='embed-container'><iframe  [src]="videoUrl" frameborder='0' allowfullscreen></iframe></div>
</ng-template>

  <ngx-spinner></ngx-spinner>