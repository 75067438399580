import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HROUTES } from "../horizontal-menu/navigation-routes.config";
import { ADMINROUTES } from "./vertical-menu-routes-admin.config";
import { ROUTES } from "./vertical-menu-routes.config";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BetaTesterType } from "app/personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.types";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import Api from "../../helpers/api";
import Me, { User } from "../../helpers/me";
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from "../services/config.service";
import { LayoutService } from "../services/layout.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  apiCall = this.api.new().silent();

  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = "assets/img/logo.png";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout: NodeJS.Timeout = null;

  isBetaTester = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private me: Me,
    private api: Api,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  isAssinante = false;

  user: User = null;
  async ngOnInit() {
    const user = await this.me.get().pipe(take(1)).toPromise();

    const jUser = JSON.parse(JSON.stringify(user));

    const login = await this.me.login(
      jUser.email,
      jUser.password,
      this.apiCall
    );

    this.user = login || null;

    if (jUser.type == "2") {
      this.menuItems = ADMINROUTES;
    } else {
      await this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const datapagamentos = await this.apiCall
        .get("seller/me/verify/subscription/plataforma")
        .pipe(take(1))
        .toPromise();
      if (datapagamentos.return) {
        if (datapagamentos.return.plano_ativo == true) {
          this.isAssinante = true;
        }
      }

      const response = await this.apiCall
        .get<BetaTesterType | null>("me/userparams/isBetaTester")
        .pipe(take(1))
        .toPromise();

      if (response.return && response.return.value === "true") {
        this.isBetaTester = true;
      }

      this.menuItems = ROUTES;

      /*if (this.isBetaTester) {
        this.menuItems = ROUTES;
      } else {
        this.menuItems = ROUTES.filter((x) => x.title !== "Acompanhamento");
      }*/
      // if(this.isAssinante && !this.menuItems.find(x=>x.title=='Telegram')){
      //   this.menuItems.push(
      //     {
      //       path: 'https://t.me/+Hw52J1-zBu9kZGM5', title: 'Telegram', icon: 'ft-send', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
      //     }
      //   )
      // }

      const dataVerifyUserTest = await this.apiCall
        .get("seller/me/hasusertest")
        .pipe(take(1))
        .toPromise();
      if (dataVerifyUserTest.return && dataVerifyUserTest.return == true) {
        if (
          this.menuItems.find((x) => x.title == "Ajuda") &&
          this.menuItems.find((x) => x.title == "Ajuda").submenu.length > 0 &&
          !this.menuItems
            .find((x) => x.title == "Ajuda")
            .submenu.find((x) => x.title == "Treinamento Inicial")
        ) {
          this.menuItems
            .find((x) => x.title == "Ajuda")
            .submenu.push({
              path: "",
              title: "Treinamento Inicial",
              isTreinamentoInicial: true,
              icon: "ft-arrow-right submenu-icon",
              src: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
        }
      }

      if (
        this.menuItems.find((x) => x.title == "Ajuda") &&
        this.menuItems.find((x) => x.title == "Ajuda").submenu.length > 0 &&
        !this.menuItems
          .find((x) => x.title == "Ajuda")
          .submenu.find((x) => x.title == "Treinamento Completo")
      ) {
        this.menuItems
          .find((x) => x.title == "Ajuda")
          .submenu.push({
            path: "https://opersonaldigital.com.br/treinamento-app",
            title: "Treinamento Completo",
            icon: "ft-arrow-right submenu-icon",
            src: "",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: true,
            submenu: [],
          });
      }

      if (
        this.menuItems.find((x) => x.title == "Ajuda") &&
        this.menuItems.find((x) => x.title == "Ajuda").submenu.length > 0 &&
        !this.menuItems
          .find((x) => x.title == "Ajuda")
          .submenu.find((x) => x.title == "Fale Conosco")
      ) {
        this.menuItems
          .find((x) => x.title == "Ajuda")
          .submenu.push({
            path: "https://api.whatsapp.com/send?phone=558398576703&text=Olá, uso a plataforma web e estou com dúvidas.",
            title: "Fale Conosco",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: true,
            submenu: [],
          });
      }

      await this.spinner.hide();
    }
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.innerWidth = event.target.innerWidth;
        this.loadLayout();
      }).bind(this),
      500
    );
  }

  goToTreinamentoInicial() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigateByUrl("/page", { state: { data: { isOnTour: true } } });
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu{
      if (this.user && (this.user.type as any) == "2") {
        this.menuItems = ADMINROUTES;
      } else {
        if (this.isBetaTester) {
          this.menuItems = ROUTES;
        } else {
          this.menuItems = ROUTES.filter((x) => x.title !== "Acompanhamento");
        }
      }
    }

    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
}
